// Common styles of the project

html, body {
  width: 100%;
  height: 100%;
  font-family: $FontRobotoRegular;
  color: #282828;
  &.hidden {
    overflow: hidden;
  }
}

.wrapper {
  width: 980px;
  margin: auto;
  @include respond-to(t) {
    width: 100%;
    padding: 0 20px;
  }
  @include respond-to(m) {
    padding: 0 15px;
  }
  &_min {
    width: 850px;
    margin: auto;
  }
}

.flex {
  display: flex;
  &_between {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.footer {
  background: #282828;
  color: #fff;
  padding-top: 80px;
  &_a {
    display: block;
    color: rgba(255, 255, 255, 0.3);
  }
  &_text {
    color: rgba(255, 255, 255, 0.3);
    font-size: 13px;
    line-height: 24px;
    margin-left: 60px;
    &:first-child {
      margin-left: 0;
    }
    @include respond-to(m) {
      margin-left: 0;
      margin-bottom: 24px;
    }
  }
  &_white {
    font-family: $FontRobotoBold;
    font-size: 15px;
    color: #fff;
    display: block;
    line-height: 40px;
  }
  &_bottom {
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
    margin-top: 10px;
    @include respond-to(t) {
      padding: 20px;
    }
    @include respond-to(m) {
      flex-direction: column-reverse;
      padding-bottom: 50px;
      height: auto;
      margin-top: 0;
    }
  }
  @include respond-to(m) {
    .flex_between {
      display: block;
      text-align: center;
    }
    &_contact {
      margin-top: 24px;
    }
  }
}

.copyright {
  opacity: 0.5;
  font-size: 13px;
}

.facility {
  width: 130px;
  @include respond-to(m) {
    margin-bottom: 24px;
  }
}

.ico {
  width: 32px;
  height: 32px;
  border: 1px #fff solid;
  border-radius: 100%;
  display: block;
  position: relative;
  @include respond-to(m) {
    margin: auto;
  }
  &:before {
    content: '';
    display: block;

    @include position(center);
  }
  &_fb {
    &:before {
      @include bg-svg($fb);
    }
  }
}

.header {
  display: flex;
  align-items: center;
  padding: 15px 50px;
  margin-bottom: 50px;
  @include respond-to(t) {
    justify-content: space-between;
    padding: 15px 20px;
    margin-bottom: 30px;
  }
  @include respond-to(m) {
    padding: 15px 15px;
  }
  .logo {
    height: 70px;
    svg {
      height: 100%;
      display: block;
    }

  }
  &_mob {
    display: none;
    @include respond-to(t) {
      display: flex;
      opacity: 0;
    }
  }
}

.menu_btn {
  display: none;
  @include respond-to(t) {
    //position: relative;
    //z-index: 100;
    cursor: pointer;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 45px;
    height: 45px;
    background: #FFFFFF;
    box-shadow: 0 8px 9px 0 rgba(120, 62, 156, 0.21), 0 8px 15px 0 rgba(120, 62, 156, 0.12);
    border-radius: 100px;

    i {
      display: block;
      width: 19px;
      height: 2px;
      background: #5736AA;
      margin-bottom: 3px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    span {
      @include position(center);
      width: 19px;
      height: 2px;
      display: block;
      background: #5736AA;
      transform: translate(-50%, -50%) rotate(45deg);
      &:last-child {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  &_mob_wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  @include respond-to(t) {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: scroll;
    text-align: center;
    z-index: 99;
    padding-bottom: 50px;
    visibility: hidden;
    transition: all .5s .5s;
    &_mob_wrapper {
      display: block;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        right: 40px;
        bottom: calc(100% - 50px);
        transform: translateX(50%);
        box-shadow: 0 8px 9px 0 rgba(120, 62, 156, 0.21), 0 8px 15px 0 rgba(120, 62, 156, 0.12);
        border-radius: 100%;
        transition: all 0.5s ease-out;
        background: #fff;
        z-index: -1;
      }
    }
    .menu_btn {
      box-shadow: none;
    }
    &.active {
      visibility: visible;
      transition: all .5s;
      .header_mob {
        opacity: 1;
      }
      .menu_mob_wrapper {
        overflow: hidden;
        &:before {
          bottom: 20px;
          right: 50%;
          width: calc(200vh - 50px);
          height: calc(200vh - 50px);
          @include respond-to(ls-t) {
            width: calc(200vw - 50px);
            height: calc(200vw - 50px);
          }
        }
      }
      .menu_lang {
        opacity: 1;
        transition: all .5s 0.2s;
      }
      .menu_site {
        opacity: 1;
        transition: all .5s 0.2s;
      }
      .menu_contact {
        opacity: 1;
        transition: all .5s 0.2s;
      }
    }
  }
  &_lang {
    display: flex;
    align-items: center;
    margin-left: 50px;

    @include respond-to(t) {
      justify-content: center;
      margin-left: 0;
      margin-bottom: 50px;
      transition: all .5s;
      opacity: 0;
    }
    &_li {
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        .menu_lang_a {
          color: #C0C0C0;
        }
      }

    }
    &_a {
      color: #282828;
      font-size: 15px;
      font-family: $FontRobotoMedium;

    }
  }
  &_site {
    display: flex;
    align-items: center;
    @include respond-to(t) {
      display: block;
      margin-bottom: 50px;
      transition: all .5s;
      opacity: 0;
    }
    &_li {
      margin-left: 55px;
      &:first-child {
        margin-left: 0;
      }
      &.active {
        @include respond-to(t) {
          margin-bottom: 40px;
        }
        .menu_site_a {
          color: #5736AA;
          position: relative;
          &:after {
            content: '';
            position: absolute;
            width: 5px;
            height: 5px;
            background: #5736AA;
            @include position(centerX);
            top: 100%;
            margin-top: 9px;
            border-radius: 100%;
          }
        }
      }
      @include respond-to(t) {
        margin-left: 0;
        margin-bottom: 30px;

      }
      position: relative;
      @include hover {
        ul {
          opacity: 1;
          visibility: visible;
        }
      }
      ul {
        position: absolute;
        left: 0;
        top: 100%;
        background: #fff;
        padding: 0 20px;
        padding-top: 23px;
        opacity: 0;
        visibility: hidden;
        transition: all .5s;
        z-index: 9999;
        @include respond-to(m) {
          display: none;
        }
        a {
          color: #282828;
          font-size: 15px;
          line-height: 18px;
          transition: all .4s;
          @include hover {
            color: #5736AA;
          }

        }
        li {
          margin-bottom: 18px;
          white-space: nowrap;
        }
      }
    }
    &_a {
      color: #282828;
      font-size: 15px;
      font-family: $FontRobotoMedium;
    }

  }
  &_contact {
    display: none;
    @include respond-to(t) {
      display: block;
      opacity: 0;
      transition: all .5s;
    }

    &_a {
      display: block;
      color: rgba(40, 40, 40, 0.3);
    }
    &_text {
      color: rgba(40, 40, 40, 0.3);
      font-size: 13px;
      line-height: 24px;
      margin-left: 60px;
      &:first-child {
        margin-left: 0;
      }
      @include respond-to(t) {
        margin-left: 0;
        margin-bottom: 24px;
      }
    }
    &_white {
      font-family: $FontRobotoBold;
      font-size: 15px;
      color: #000;
      display: block;
      line-height: 40px;
    }
    &_bottom {
      height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 50px;
      margin-top: 10px;
      @include respond-to(t) {
        flex-direction: column-reverse;
        padding-bottom: 100px;
        height: auto;
        margin-top: 0;
      }
      @include respond-to(m) {
        padding-bottom: 50px;
      }
    }
    @include respond-to(t) {
      .flex_between {
        display: block;
        text-align: center;
      }
      &_contact {
        margin-top: 24px;
      }
    }
    .facility {
      @include respond-to(t) {
        margin-bottom: 24px;
      }
    }
    .ico {
      border-color: #282828;
      margin: auto;
      &_fb {
        &:before {
          @include bg-svg($fb_black);
        }
      }
    }
  }
}

.structure {
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 126px;
  @include respond-to(t) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &_item {
    margin-bottom: 60px;
    width: 460px;
    height: 155px;
    margin-right: 60px;
    background: rgba(240, 237, 246, 0.4);
    box-shadow: 0 0px 0px 0 rgba(113, 69, 203, 0);
    border-radius: 4px;
    display: flex;
    align-items: baseline;
    font-family: $FontMontserratSemiBold;
    font-size: 21px;
    line-height: 32px;
    color: #282828;
    padding-top: 45px;
    padding-left: 100px;
    transition: all .5s;
    position: relative;
    &:nth-child(even) {
      margin-right: 0;
    }
    span {
      font-size: 15px;
      color: #6C6C6C;
      font-family: $FontRobotoRegular;
      line-height: 24px;
      margin-right: 15px;
      opacity: 0.5;
      position: relative;
    }

    @include hover {
      background: #FFFFFF;
      box-shadow: 0 14px 32px 0 rgba(113, 69, 203, 0.11);
      .btn_right {
        opacity: 1;
      }
      .structure_circle {
        i {

          background: #D1C5EB;
        }
        &:before {
          border: none;

          background: #E2DBF3;
        }
        &:after {
          border: none;

          background: #F6F3FB;

        }
      }
    }
    @include respond-to(t) {
      background: #FFFFFF;
      box-shadow: 0 14px 32px 0 rgba(113, 69, 203, 0.11);
      .structure_circle {
        i {

          background: #D1C6EB;
        }
        &:before {
          border: none;

          background: rgba(209, 198, 235, 0.54);
        }
        &:after {
          border: none;

          background: rgba(209, 198, 235, 0.20);

        }
      }
      margin-right: 0;
      max-width: 100%;
    }
    @include respond-to(m) {
      height: auto;
      min-height: 155px;
      padding-bottom: 30px;
    }
  }
  .btn_right {
    @include position(center);
    left: 100%;
    opacity: 0;
    transition: all .5s;
    @include respond-to(t) {
      opacity: 1;
      left: 50%;
      top: 100%;
    }
  }
  &_circle {
    position: absolute;
    left: -40px;

    i {
      @include position(center);
      width: 8px;
      height: 8px;
      z-index: 3;
      border-radius: 100%;
      background: #E0E0E0;
      transition: all .3s;
    }
    &:before {
      @include position(center);
      content: '';
      width: 18px;
      height: 18px;
      z-index: 2;
      border: 1px #E0E0E0 solid;
      border-radius: 100%;
      transition: all .3s;
    }
    &:after {
      @include position(center);
      content: '';
      width: 30px;
      height: 30px;
      z-index: 1;
      border: 1px #E0E0E0 solid;
      border-radius: 100%;
      transition: all .3s;
    }

  }
}

.tab {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @include respond-to(m) {
    display: block;
  }
  &_link {
    display: block;
    font-size: 15px;
    color: #6C6C6C;
    span {
      font-size: 15px;
      color: #6C6C6C;
      opacity: 0.2;
      font-family: $FontRobotoRegular;
    }
    &.active {
      color: #282828;
      font-size: 21px;
      font-family: $FontMontserratSemiBold;
      span {
        opacity: 0.5;
      }
    }
    @include respond-to(m) {
      margin-bottom: 30px;
      white-space: nowrap;
    }
  }
}

.commision {
  margin-top: 100px;
  @include respond-to(t) {
    margin-top: 50px;
  }
}

.c-table {
  display: table;
  width: 100%;
  @include respond-to(t) {
    display: block;
    width: 100%;
    text-align: center;
  }
  &_tr {
    display: table-row;
    &:last-child {
      .c-table_td {
        border-bottom: none;
      }
    }
    @include respond-to(t) {
      display: block;
      width: 100%;
      text-align: center;
      border-bottom: #EEECF2 1px solid;
    }
  }
  &_td {
    display: table-cell;
    line-height: 24px;
    border-bottom: #EEECF2 1px solid;
    padding-top: 60px;
    padding-bottom: 67px;
    font-size: 15px;
    color: #6C6C6C;
    @include respond-to(t) {
      display: block;
      width: 100%;
      text-align: center;
      height: auto;
      border-bottom: none;
      padding: 0;
    }
  }
  &_contact {
    line-height: 24px;
    font-size: 15px;
    color: #6C6C6C;
    a {
      color: #6C6C6C;
      display: block;
    }
    @include respond-to(t) {
      padding-bottom: 50px;
    }
  }
  &_tel {
    margin-bottom: 24px;
    color: #282828;
    font-family: $FontRobotoMedium;
    display: flex;
    @include respond-to(t) {
      display: block;
      width: 100%;
      text-align: center;
    }
    a {
      color: #282828;
      margin-right: 20px;
      white-space: nowrap;
      @include respond-to(t) {
        margin-right: 0;
      }
    }
  }

  &_name {
    color: #282828;
    font-size: 15px;
    font-family: $FontRobotoMedium;
    width: 200px;
    @include respond-to(t) {
      display: block;
      width: 100%;
      text-align: center;
      padding-top: 53px;
      padding-bottom: 24px;
    }
  }
  &_text {
    padding-left: 35px;
    position: relative;
    width: 400px;
    padding-right: 70px;
    &:before {
      content: '';
      position: absolute;
      top: 3px;
      left: 0;
      @include bg-svg($air-right-grey)
    }
    @include respond-to(t) {
      display: block;
      width: 100%;
      text-align: center;
      padding-bottom: 24px;
      padding-right: 35px;
    }
  }
}

.heads {
  margin-top: 100px;
  &_item {
    margin-bottom: 20px;
    position: relative;
    @include respond-to(t) {
      margin-bottom: 100px;
    }
  }
  &_flex {
    display: flex;
    align-items: center;
    height: 236px;
    background: #FFFFFF;
    box-shadow: 0 6px 15px 0 rgba(113, 69, 203, 0.11);
    @include respond-to(t) {
      display: block;
      height: auto;
      text-align: center;
    }
  }
  &_image {
    width: 146px;
    height: 200px;
    background: #EBE8F3;
    box-shadow: 0 8px 15px 0 rgba(113, 69, 203, 0.11);
    border-radius: 40%;
    padding: 10px;
    margin-left: 18px;
    margin-right: 36px;
    @include respond-to(t) {
      margin: auto;
      margin-bottom: 24px;
    }
  }

  &_img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    box-shadow: 0 8px 15px 0 rgba(88, 75, 117, 0.26);
    border-radius: 40%;
    border: 5px #D5D2DC solid;

  }

  &_text {
    line-height: 24px;
    font-size: 15px;
    color: #6C6C6C;
    padding-left: 35px;
    position: relative;
    min-width: 400px;
    width: 400px;
    &:before {
      content: '';
      position: absolute;
      top: 3px;
      left: 0;
      @include bg-svg($air-right);
      @include respond-to(t) {
        left: 10px;
      }
    }

    @include respond-to(t) {
      display: block;
      width: 100%;
      min-width: 0;
      text-align: center;
      padding-bottom: 24px;
      padding-right: 35px;
    }
  }
  &_contact {
    line-height: 24px;
    font-size: 15px;
    color: #6C6C6C;
    @include respond-to(t) {
      padding-bottom: 50px;
    }

    a {
      color: #6C6C6C;
      display: block;
    }
  }
  &_tel {
    margin-bottom: 24px;
    color: #282828;
    font-family: $FontRobotoMedium;
    display: flex;
    @include respond-to(t) {
      display: block;
      width: 100%;
      text-align: center;
    }
    a {
      color: #282828;
      margin-right: 20px;
      @include respond-to(t) {
        margin-right: 0;
      }
    }
  }
  &_title {
    color: #282828;
    font-size: 18px;
    margin-bottom: 24px;
    font-family: $FontRobotoMedium;
  }
  &_content {

    background: url("../img/general/airbg.png") right bottom no-repeat;
    background-color: #F0EDF6;
    display: none;

    li {
      font-size: 15px;
      line-height: 24px;
      color: #6C6C6C;
      margin-bottom: 24px;
      position: relative;
      padding-left: 26px;
      &:before {
        content: '';
        width: 8px;
        height: 8px;
        background: #D1C6EB;
        position: absolute;
        left: 0;
        top: 8px;
        border-radius: 100%;

      }
      strong {
        font-family: $FontRobotoMedium;
        color: #000;
      }
    }
  }
  &_p {
    padding: 75px 160px 65px 210px;
    @include respond-to(t) {
      padding: 50px 10px;
    }
  }
  .btn_plus {
    position: absolute;
    right: -22px;
    bottom: 95px;
    @include respond-to(t) {
      @include position(center);
      top: 100%;
    }
  }
}

.list {
  margin-top: 70px;
  .title_min {
    text-transform: uppercase;
    font-family: $FontRobotoBold;
  }
  &_wrap {
    margin-top: 20px;
    margin-bottom: 70px;
  }
  &_min {
    border-bottom: 1px #EEECF2 solid;
    height: 71px;
    display: flex;
    align-items: center;
    line-height: 24px;
    &:last-child {
      border-bottom: none;
    }
  }
  &_big {
    position: relative;
    background: #FFFFFF;
    box-shadow: 0 6px 15px 0 rgba(113, 69, 203, 0.11);
    margin-top: 20px;
  }
  &_title {
    height: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #282828;
    font-size: 21px;
    font-family: $FontRobotoBold;
    line-height: 36px;
    text-align: center;
  }
  .btn_plus {
    position: absolute;
    right: -22px;
    bottom: 95px;
    @include respond-to(t) {
      @include position(center);
      top: 100%;
    }
  }
  &_content {
    background-color: #F0EDF6;
    display: none;
    h1, h2, h3 {
      color: #282828;
      font-size: 15px;
      text-transform: uppercase;
      font-family: $FontRobotoRegular;
      text-align: center;
      margin-bottom: 24px;
      line-height: 48px;
    }
    p {
      margin-bottom: 24px;
      color: #6C6C6C;
    }
    li {
      font-size: 15px;
      line-height: 24px;
      color: #6C6C6C;
      margin-bottom: 48px;
      position: relative;
      padding-left: 26px;
      &:before {
        content: '';
        width: 5px;
        height: 5px;
        background: #5736AA;
        position: absolute;
        left: 0;
        top: 8px;
        border-radius: 100%;

      }
      strong {
        font-family: $FontRobotoMedium;
        color: #000;
        margin-bottom: 24px;
      }
    }
  }
  &_p {
    padding: 75px 160px 65px 160px;
    @include respond-to(t) {
      padding: 50px 10px;
    }
  }
}

//////////////////

.content {
  position: relative;
}

.home_top {
  position: relative;
  .btn {
    margin-top: 47px;
    @include respond-to(t) {
      margin-left: auto;
      margin-right: auto;
    }
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 0;;
    left: 25%;
    transform: translate(-50%, 30%);
    background: #FFFFFF;
    box-shadow: -31px 10px 42px 1px rgba(113, 69, 203, 0.11);
    width: 70vw;
    height: 70vw;
    border-radius: 100%;
    z-index: -1;
    @include respond-to(t) {
      width: 70vh;
      height: 70vh;
    }
    @include respond-to(ls-t) {
      width: 70vw;
      height: 70vw;
    }
  }

  @include respond-to(t) {
    text-align: center;
  }
}

.home-slider {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  @include respond-to(t) {
    flex-direction: column-reverse;
    br {
      display: none;
    }
  }
  &_control {
    margin-right: 20px;
    @include respond-to(t) {
      margin: auto;
      display: flex;
      position: relative;
      width: 100%;
      justify-content: center;
    }
  }
  &_btn {
    position: relative;
    cursor: pointer;
    margin-top: -10px;
    &:before {
      content: '';
      @include position(center);
      width: 3px;
      height: 3px;
      background: #DBD2F1;
      border-radius: 100%;
    }
    &.active {
      .home-slider {
        &_content {
          opacity: 1;
          visibility: visible;
        }
        &_line {
          &:before {
            width: 100%;
          }
        }
        &_index {
          transform: scale(1);
          svg {
            transition: opacity .1s, stroke-dashoffset 5s .5s;
            opacity: 1;
            stroke-dashoffset: -251px;
          }
        }
        &_title {
          transform: none;
          transition: all .7s;
          opacity: 1;
        }
        &_text {
          transform: none;
          transition: all .7s .2s;
          opacity: 0.5;
        }

      }
    }
    @include respond-to(t) {
      margin: 0;
    }
  }
  &_index {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5736AA;
    cursor: pointer;
    font-size: 14px;
    font-family: $FontMontserratSemiBold;
    border: 1px #DBD2F1 solid;
    border-radius: 100%;
    transform: scale(0);
    background: #fff;
    transition: all .5s;

    svg {
      position: absolute;
      top: -2px;
      left: -2px;
      width: 44px;
      height: 44px;;

      stroke-dasharray: 126px;
      stroke-dashoffset: -126px;
      transform: rotate(-90deg);
      transition: opacity .3s, stroke-dashoffset 0s .3s;
      opacity: 0;
    }

  }
  &_next {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 100%;
    background: #EAE7F1;
    margin-top: 10px;
    &:before {
      content: '';
      @include bg-svg($air-bottom);
      opacity: 0.1;
      transition: all .5s;
    }
    @include hover {
      &:before {
        opacity: .5;
      }
    }

    @include respond-to(t) {
      margin: 0;
      margin-left: 10px;
      &:before {
        @include bg-svg($air-right);
      }
    }

  }
  &_content {
    position: absolute;
    top: 50%;
    right: 100%;
    padding-right: 160px;
    margin-right: 20px;
    visibility: hidden;
    opacity: 0;
    transition: all .5s;

    @include respond-to(t) {
      top: 0;
      left: 0;
      width: 100%;
      display: none;
    }
  }
  &_content_mob {
    display: none;
    &_wrap {
      display: none;
      @include respond-to(t) {
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    @include respond-to(t) {
      .home-slider {
        &_title {
          width: 100%;
          text-align: center;

        }
        &_text {
          width: 100%;
          text-align: center;

        }
      }
      transition: all .3s;
      &.active {
        display: block;
        opacity: 0;
        &.show {
          opacity: 1;
        }
        .home-slider {
          &_content {
            opacity: 1;
            visibility: visible;
          }
          &_line {
            &:before {
              width: 100%;
            }
          }
          &_index {
            transform: scale(1);
            svg {
              transition: opacity .1s, stroke-dashoffset 5s .5s;
              opacity: 1;
              stroke-dashoffset: -251px;
            }
          }
          &_title {
            transform: none;
            transition: all .7s;
            opacity: 1;
          }
          &_text {
            transform: none;
            transition: all .7s .2s;
            opacity: 0.5;
          }

        }
      }
    }
  }

  &_title {
    color: #6C6C6C;
    font-size: 15px;
    line-height: 24px;
    margin-top: -12px;
    width: 170px;
    transform: translateY(20px);
    transition: all 0s .5s;
    margin-bottom: 25px;
    opacity: 0;
  }
  &_text {
    color: #6C6C6C;
    font-size: 15px;
    line-height: 24px;
    opacity: 0;
    transform: translateY(20px);
    transition: all .5s .5s;
  }
  &_line {
    width: 150px;
    height: 2px;
    position: absolute;
    top: 0;
    right: 0;
    &:before {
      content: '';
      width: 0;
      height: 2px;
      position: absolute;
      top: 0;
      right: 0;
      background: #E0E0E0;
      transition: all .5s;
    }
    @include respond-to(t) {
      display: none;
    }
  }
  &_anim {
    width: 458px;
    position: relative;
    margin: 0px -20px 0px 0;
    &_content {
      @include position(centerX);
      top: 35px;
      width: 378px;
      height: 520px;
      overflow: hidden;
      border-radius: 41%;
      @include respond-to(m) {
        top: 15px;
        width: 250px;
        height: 346px
      }
      svg {
        width: 378px;
        position: relative;
        display: block;
        z-index: 999;
        @include respond-to(m) {
          width: 250px;
        }
      }
    }
    &.hide {
      .home-slider_shade {
        transform: none;
      }
    }
    @include respond-to(t) {
      margin: auto;

    }
    @include respond-to(m) {
      width: 300px;
    }
  }
  &_slide {
    width: 100%;
    height: 100%;
    border-radius: 42%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 2px;

  }
  &_img {
    width: 100%;
    height: 100%;
    background-size: cover;
    border-radius: 42%;
    //display: none;
    //&:first-child {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("../img/content/home/slider/cloud.png") center;
      background-size: cover;
      animation: cloud 20s linear infinite;
      @include respond-to(t) {
        animation: none;
      }
    }
    //}
    &.hide {
      display: none;
    }
    &.active {
      display: block;
    }
  }
  &_shade {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 2;
    transform: translateY(-80%);
    background: url("../img/content/home/slider/hide.jpg") bottom center;
    background-size: cover;
    transition: all .3s ease-out;

  }
  &_plane {
    opacity: 0;
    visibility: hidden;
    display: block;
    position: absolute;
    left: 60px;
    width: 0;
    &.active {
      opacity: 1;
      visibility: visible;
      width: 100%;
      transition: all 1s ease-in;
      @include respond-to(m) {
        width: 80%;
      }
    }
    &.out {
      width: 150%;
      opacity: 0;
      left: 100%;
      transition: all .5s ease-out;
    }
    &_wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9;
    }
  }

}

.over-hide {
  width: 100%;
  overflow: hidden;
  margin: -50px 0px -50px;
  @include respond-to(t) {
    margin: 50px auto;
  }
}

@keyframes cloud {
  0% {
    background-position: 0;
  }
  100% {
    background-position: -300%;
  }

}

.home-about {
  @include respond-to(t) {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
  &_text {
    column-count: 2;
    column-gap: 70px;
    font-size: 15px;
    color: #6C6C6C;
    margin: 56px 0 49px;
    @include respond-to(t) {
      column-count: 1;
      br {
        display: none;
      }
    }
  }
}

.member {
  padding-top: 150px;
  padding-bottom: 150px;
  position: relative;
  overflow: hidden;
  @include respond-to(t) {
    @include respond-to(only-t) {
      .wrapper {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 50px;
        padding: 0;
      }
    }
  }
  @include respond-to(m) {
    padding-top: 110px;
    padding-bottom: 110px;
  }
  &_text {
    max-width: 320px;
    font-size: 15px;
    color: #6C6C6C;
    margin: 58px 0 60px;
  }
  &_form, &_done {
    @include position(centerY);
    left: 50%;
    background: #FFFFFF;
    border-radius: 6px;
    padding-left: 130px;
    svg {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
    @include respond-to(t) {
      @include position(none);
      padding-top: 1px;
      width: 710px;
      margin-left: auto;
      margin-right: auto;
    }
    @include respond-to(m) {
      padding: 0;
      width: 300px;
      padding-top: 100px;
      margin-top: 50px;
    }
  }
  &_flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 48px;
    margin-top: 38px;
    @include respond-to(m) {
      display: block;
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  &_wrap {
    width: 460px;
    position: relative;
    padding-bottom: 20px;
    .btn {
      position: absolute;
      bottom: 0;
      margin-bottom: -22px;
      left: 0;
      @include respond-to(m) {
        margin-bottom: -48px;
        left: 0;
        width: 100%;
      }
    }
    @include respond-to(m) {
      width: 220px;
      margin: auto;
    }

    &:after {
      content: '';
      width: 190px;
      height: 190px;
      background: url('../img/general/form.png') top right no-repeat;
      background-size: contain;
      opacity: 0;
      transition: all 0.5s;
      pointer-events: none;
      position: absolute;
      top: 95px;
      right: -30px;
    }
  }
  .form-group {
    display: block;
    position: relative;
    &.has-error {
      .help-block {
        opacity: 1;
        visibility: visible;
      }
    }
    @include respond-to(m) {
      margin-bottom: 25px;
    }
  }
  .control-label {
    display: block;
    font-family: $FontMontserratSemiBold;
    color: #6C6C6C;
    font-size: 15px;
    margin-bottom: 9px;
  }
  .help-block {
    position: absolute;
    right: 0;
    top: 100%;
    font-size: 12px;
    color: #E94E71;
    margin-top: 4px;
    opacity: 0;
    visibility: hidden;
    transition: all .5s;
  }
  .form-control {
    display: block;
    width: 220px;
    height: 43px;
    background: rgba(237, 236, 241, 0.40);
    font-size: 15px;
    color: #282828;
    padding-left: 20px;
    border-radius: 6px;

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #C0C0C0;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: #C0C0C0;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: #C0C0C0;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: #C0C0C0;
    }
  }
  &_status {
    color: #DDD9E6;
    font-size: 15px;
    position: absolute;
    left: 0;
    top: 0;
    width: 55px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      transform: rotate(-90deg);
      white-space: nowrap;
    }

  }
  &_crop {
    position: absolute;
    left: 0;
    transform: translate(0, -50%);
    width: 26px;
    height: 26px;
    border-radius: 100%;
    background: #fff;
    box-shadow: 0 3px 15px 0 rgba(113, 69, 203, 0.11) inset;
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      height: 50%;
      background: #fff;
    }
    &.top {
      top: 0;
      &:after {
        top: 0;
      }
    }
    &.bottom {
      bottom: 0;
      transform: translate(0, 50%);
      &:after {
        bottom: 0;
      }
    }
    &_wrap {
      position: absolute;
      height: 100%;
      right: -13px;
      top: 0;
      width: 26px;
      overflow: hidden;
    }
  }
  &_tablet {
    @include respond-to(m) {
      display: none !important;
    }
  }
  &_mobile {
    display: none;
    @include respond-to(m) {
      display: block;
    }
  }
}

.transfers {
  margin-bottom: 70px;
  &_wrap {
    display: flex;
    justify-content: space-between;
    text-align: center;
    margin-top: 58px;

  }
  @include respond-to(m) {
    margin-bottom: 20px;
  }
  &_item {
    width: 247px;
    cursor: pointer;
    @include hover {
      .transfers_shade {
        top: -50%;
      }
    }
    &.active {
      .transfers_shade {
        top: -90%;
      }
    }
  }
  &_image {
    width: 81px;
    height: 115px;
    margin: auto;
    margin-bottom: 31px;

    box-shadow: 0 25px 18px 0 rgba(87, 54, 170, 0.12);
    border-radius: 30%;
    position: relative;
    overflow: hidden;
    background-clip: content-box;
    &:before {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      border-radius: 30%;
      background-image: linear-gradient(35deg, #3DBDFF 0%, #8D5AFF 100%);
    }
    @include respond-to(m) {
      width: 60px;
      height: 90px;
    }
  }
  &_ico {
    @include position(center);

  }
  &_shade {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 2;
    top: -10%;
    //transform: translateY(-10%);
    background: url("../img/content/home/slider/hide.jpg") bottom center;
    background-size: cover;
    transition: all .3s ease-out;

    span {
      @include position(center);
      color: #DDD9E6;
      font-size: 24px;
      font-family: $FontRobotoMedium;
    }
  }
  &_title {
    color: #282828;
    font-family: $FontRobotoMedium;
    line-height: 27px;
    display: flex;
    margin-right: 80px;
    min-width: 200px;
    span {
      font-family: $FontRobotoBold;
      color: #DDD9E6;
      font-size: 17px;
      margin-right: 10px;
    }
  }
  &_text {
    color: #6C6C6C;
    font-size: 15px;
    line-height: 24px;
    width: 100%;
  }
  &_slide {
    .flex_between {
      display: flex;
      width: 750px;
      margin: auto;
      justify-content: space-between;
      margin-top: 80px;
      @include respond-to(t) {
        width: 700px;
        br {
          display: none;
        }
      }
      @include respond-to(m) {
        display: block;
        margin-top: 30px;
        width: 100%;
      }
    }
  }
}

.partners {
  padding-top: 70px;
  padding-bottom: 170px;
  background: url("../img/content/map.png") top right no-repeat;
  width: 100%;
  overflow: hidden;
  @include respond-to(m) {
    padding-bottom: 100px;
  }
  &_text {
    margin: 54px 0 40px;
  }
  &_wrap {
    width: 370px;
  }
  &_flex {
    display: flex;
    justify-content: space-between;
    @include respond-to(t) {
      position: relative;
    }
    @include respond-to(m) {
      flex-direction: column-reverse;
    }
  }
  &_img {
    width: 590px;

    background: #FFFFFF;
    box-shadow: 0 8px 15px 0 rgba(113, 69, 203, 0.06);
    border-radius: 170px;
    padding: 30px;
    img {
      display: block;
      width: 100%;
      border: 10px #fff solid;
      box-shadow: 0 8px 15px 0 rgba(113, 69, 203, 0.06);
      border-radius: 140px;
    }
    @include respond-to(m) {
      width: 320px;
      padding: 10px;
    }
  }
  &_slider {
  }
  &_slide {
    width: 266px;
    height: 140px;
    background: #FFFFFF;
    box-shadow: 0 8px 15px 0 rgba(113, 69, 203, 0.11);
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      display: block;
      max-width: 90%;
      max-height: 90%;
    }
  }
  .bx-viewport {
    overflow: visible !important;
    margin-left: -133px;
    margin-top: -70px;
    @include respond-to(m) {
      margin-left: 30px;
    }
  }
  &_control {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
    @include respond-to(t) {
      position: absolute;
      top: 100%;
      margin-top: 100px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &_prev {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5736AA;
    cursor: pointer;
    font-size: 14px;
    font-family: $FontMontserratSemiBold;
    border: 1px #E0E0E0 solid;
    border-radius: 100%;
    //transform: rotate(180deg);
    background: #fff;
    //transition: all .5s;
    position: relative;
    .arrow-right {
      margin: 0;
      @include position(center);
      @include bg-svg($arrow_right2);
    }

    .arrow-left {
      margin: 0;
      @include position(center);
      @include bg-svg($arrow_left2);
    }
  }
  &_next {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5736AA;
    cursor: pointer;
    font-size: 14px;
    font-family: $FontMontserratSemiBold;
    border: 1px #E0E0E0 solid;
    border-radius: 100%;
    margin-left: 12px;
    background: #fff;
    //transition: all .5s;
    position: relative;
    svg {
      position: absolute;
      top: -2px;
      left: -2px;
      width: 44px;
      height: 44px;;
      stroke-dasharray: 126px;
      stroke-dashoffset: -126px;
      transform: rotate(-90deg);
      transition: opacity .3s, stroke-dashoffset 0s .3s;
      opacity: 0;
    }
    &.active {
      svg {
        transition: opacity .1s, stroke-dashoffset 5s .5s;
        opacity: 1;
        stroke-dashoffset: -251px;
      }
    }
    .arrow-right {
      margin: 0;
      @include position(center);
      @include bg-svg($arrow_right2);
    }

  }

}

.home_news {
  margin-bottom: 100px;
  width: 100%;
  overflow: hidden;
  padding-bottom: 50px;

  @include respond-to(m) {
    position: relative;
  }
  .title {
    margin-top: 50px;
    @include respond-to(m) {
      br {
        display: none;
      }
    }
  }
  &_control {
    display: flex;
    align-items: center;
    margin-top: 43px;
    margin-bottom: 115px;
    @include respond-to(m) {
      justify-content: center;
      position: absolute;
      left: 0;
      top: 500px;
      margin: 0;
      width: 100%;
    }
  }
  &_left {
    display: inline-block;
    @include respond-to(m) {
      display: block;
    }
  }
  &_btn {
    position: relative;
    cursor: pointer;
    margin-left: -10px;
    &:before {
      content: '';
      @include position(center);
      width: 3px;
      height: 3px;
      background: #DBD2F1;
      border-radius: 100%;
    }
    &.active {
      .home_news {
        &_index {
          transform: scale(1);
          svg {
            transition: opacity .1s, stroke-dashoffset 5s .5s;
            opacity: 1;
            stroke-dashoffset: -251px;
          }
        }
      }
    }
  }
  &_index {
    width: 42px;
    min-width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5736AA;
    cursor: pointer;
    font-size: 14px;
    font-family: $FontMontserratSemiBold;
    border: 1px #DBD2F1 solid;
    border-radius: 100%;
    transform: scale(0);
    background: #fff;
    transition: all .5s;

    svg {
      position: absolute;
      top: -2px;
      left: -2px;
      width: 44px;
      height: 44px;;

      stroke-dasharray: 126px;
      stroke-dashoffset: -126px;
      transform: rotate(-90deg);
      transition: opacity .3s, stroke-dashoffset 0s .3s;
      opacity: 0;
    }
  }
  &_next {
    width: 42px;
    min-width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 100%;
    background: #EAE7F1;
    transform: rotate(-90deg);
    margin-left: 10px;
    &:before {
      content: '';
      @include bg-svg($air-bottom);
      opacity: 0.1;
      transition: all .5s;
    }
    @include hover {
      &:before {
        opacity: .5;
      }
    }
  }
  .btn_text {
    float: right;
    &.mob {
      display: none;
    }
    @include respond-to(m) {
      display: none;
      &.mob {
        display: block;
        float: none;
        margin-top: 100px;
        text-align: center;
      }
    }
  }
  &_slider {
    font-size: 0;
    white-space: nowrap;
    display: inline-block;
    width: 300px;
    margin-left: 70px;
    vertical-align: top;
    position: relative;
    @include respond-to(m) {
      margin-left: 0;
      margin-top: 30px;
    }
  }
  &_slide {
    font-size: 15px;
    color: #B5B5B5;
    display: inline-block;
    width: 320px;
    background: #FFFFFF;
    box-shadow: 0 14px 32px 0 rgba(113, 69, 203, 0.11);
    border-radius: 4px;
    margin-right: 10px;
    height: 360px;
    vertical-align: top;
    position: relative;
    transition: all .5s;
    white-space: normal;
    &:nth-child(2) {
      opacity: .75;
    }
    &:nth-child(3) {
      opacity: .50;
    }
    &:nth-child(4) {
      opacity: .35;
    }
    &:nth-child(5) {
      opacity: .20;
    }
    &:nth-child(6) {
      opacity: .10;
    }
    &.hide {
      opacity: 0;
      transform: scale(0.2);
    }
    &.noanim {
      transition: opacity .5s, margin 0s;
    }
  }
  &_image {
    width: 100%;
    height: 196px;
    background-size: cover;
    display: block;
  }
  &_title {
    padding: 20px 30px;
    font-family: $FontRobotoMedium;
    font-size: 18px;
    color: #282828;
    line-height: 27px;
  }
  &_time {
    position: absolute;
    left: 30px;
    bottom: 25px;
  }
  &_view {
    position: absolute;
    right: 30px;
    bottom: 25px;
    &:before {
      content: '';
      display: inline-block;
      @include bg-svg($view);
      margin-right: 5px;
      vertical-align: middle;
    }
  }
}

.about-doc {
  padding-bottom: 150px;
  background: url("../img/general/airbg.jpg") right bottom no-repeat;
  .wrapper {
    width: 850px;
    @include respond-to(t) {
      width: 100%;
      justify-content: space-around;
    }
    @include respond-to(m) {
      display: block;
    }
  }
  &_title {
    font-size: 21px;
    font-family: $FontMontserratSemiBold;
    line-height: 36px;
    color: #282828;
    margin-bottom: 34px;
  }
  &_item {
    padding: 35px 61px 0 44px;
    width: 320px;
    height: 126px;
    font-size: 15px;
    color: #282828;
    line-height: 24px;
    background: #FFFFFF;
    box-shadow: 0 14px 32px 0 rgba(113, 69, 203, 0.11);
    border-radius: 4px;
    margin-bottom: 21px;
    position: relative;
    font-family: $FontRobotoMedium;
    margin-right: 100px;
    @include respond-to(t) {
      margin-right: 0;
    }
    @include respond-to(m) {
      width: 100%;
      text-align: center;
      margin-bottom: 60px;
    }
  }

  .btn_download {
    @include position(center);
    left: 100%;
    @include respond-to(m) {
      @include position(center);
      top: 100%;
    }
  }
}

.timeline {
  position: absolute;
  top: 150px;
  left: 50px;
  height: calc(100% - 150px);
  width: 1px;
  @include respond-to(t) {
    display: none;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 1px;
    background: url("../img/content/home/line.png");
  }
  &_pin {
    width: 8px;
    height: 8px;
    background: #D1C6EB;
    border-radius: 100%;
    transform: translateX(-50%);
    position: absolute;
    transition: all .5s;
    left: 0;
    &.hide {
      opacity: 0;
      transition: all 0s;
    }
    &.active {
      width: 1px;
      height: 1px;
    }
  }
  &_air {

    position: absolute;
    top: 0;
    left: 0;
    background: #EEEEEE;
    width: 1px;
    svg {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateX(-50%);
      transition: all .5s;
      &.rotate {
        transform: translateX(-50%) rotate(180deg);

      }
    }
  }
}

.doc {
  text-align: center;
  margin-bottom: 120px;
  margin-top: 120px;
  &_item {
    width: 280px;
    margin-top: 61px;
    @include respond-to(m) {
      width: 100%;
    }
  }
  &_link {
    display: block;
    background: #FFFFFF;
    box-shadow: 0 14px 32px 0 rgba(113, 69, 203, 0.11);
    border-radius: 4px;
    width: 185px;
    margin: auto;
    border: 17px #fff solid;
    border-bottom: 10px #fff solid;
    border-top: 10px #fff solid;
    margin-bottom: 46px;
    transition: all .5s;
    @include hover {
      box-shadow: 0 0px 32px 0 rgba(113, 69, 203, 0.11);
    }
  }
  &_img {
    display: block;
    width: 100%;
  }
  &_reg {
    color: rgba(108, 108, 108, 0.5);
    font-size: 13px;
    line-height: 24px;
    margin-top: 16px;
    padding: 0 20px;
  }
  @include respond-to(m) {
    .flex_between {
      display: block;
    }
  }
}

.statute {
  margin-top: 180px;
  @include respond-to(m) {
    margin-top: 70px;
  }

  &_item {
    width: 450px;
    margin-left: 100px;
    @include respond-to(t) {
      margin-left: 20px;
      width: 360px;
    }
    .title_min {
      margin-bottom: 54px;
      @include respond-to(m) {
        margin-bottom: 30px;
        margin-top: 50px;
      }
    }
    & li {
      font-size: 15px;
      line-height: 24px;
      color: #6C6C6C;
      margin-bottom: 24px;
      position: relative;
      padding-left: 26px;
      &:before {
        content: '';
        width: 8px;
        height: 8px;
        background: #D1C6EB;
        position: absolute;
        left: 0;
        top: 8px;
        border-radius: 100%;

      }
    }
    &:first-child {
      width: 360px;
      @include respond-to(m) {
        width: 100%;
      }
      .title_min {
        margin-left: -100px;
        @include respond-to(t) {
          margin-left: 0;
        }
      }
    }
    @include respond-to(m) {
      width: 100%;
      margin-left: 0;
    }
  }
  @include respond-to(m) {

    .flex_between {
      display: block;
    }
  }
}

.about-slider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  @include respond-to(t) {
    justify-content: space-around;
  }
  @include respond-to(m) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  &_anim {
    width: 458px;
    position: relative;
    margin: 0px 0px 0px -60px;
    @include respond-to(t) {
      margin-left: -30px;

    }
    @include respond-to(m) {
      margin: 0;
      width: 305px;
      svg {
        width: 255px;
      }
    }
    &_content {
      @include position(centerX);
      top: 35px;
      width: 378px;
      height: 520px;
      overflow: hidden;
      border-radius: 41%;
      @include respond-to(m) {
        width: 208px;
        height: 290px;
        top: 20px;
      }
      svg {
        width: 378px;
        position: relative;
        display: block;
        z-index: 999;
        @include respond-to(m) {
          width: 208px;
          height: 290px;
        }
      }
    }
    &.hide {
      .home-slider_shade {
        transform: none;
      }
    }
  }
  &_img {
    width: 100%;
    height: 100%;
    background-size: cover;
    border-radius: 42%;
    visibility: hidden;
    opacity: 0;
    transition: all .5s;
    position: absolute;
    top: 0;
    left: 0;
    &.active {
      visibility: visible;
      opacity: 1;
    }
  }
  &_slide {
    width: 100%;
    height: 100%;
    border-radius: 42%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 2px;
  }
  &_content {
    width: 382px;
    @include respond-to(t) {
      width: 300px;
    }
  }
  &_slide-in {
    width: 382px;
  }
  &_btn {
    position: relative;
    cursor: pointer;
    margin-left: -10px;
    &:before {
      content: '';
      @include position(center);
      width: 3px;
      height: 3px;
      background: #DBD2F1;
      border-radius: 100%;
    }
    &.active {
      .about-slider {
        &_index {
          transform: scale(1);
          svg {
            transition: opacity .1s, stroke-dashoffset 5s .5s;
            opacity: 1;
            stroke-dashoffset: -251px;
          }
        }
      }
    }
  }
  &_index {
    width: 42px;
    min-width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5736AA;
    cursor: pointer;
    font-size: 14px;
    font-family: $FontMontserratSemiBold;
    border: 1px #DBD2F1 solid;
    border-radius: 100%;
    transform: scale(0);
    background: #fff;
    transition: all .5s;

    svg {
      position: absolute;
      top: -2px;
      left: -2px;
      width: 44px;
      height: 44px;;

      stroke-dasharray: 126px;
      stroke-dashoffset: -126px;
      transform: rotate(-90deg);
      transition: opacity .3s, stroke-dashoffset 0s .3s;
      opacity: 0;
    }
  }
  &_next {
    width: 42px;
    min-width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 100%;
    background: #EAE7F1;
    transform: rotate(-90deg);
    margin-left: 10px;
    &:before {
      content: '';
      @include bg-svg($air-bottom);
      opacity: 0.1;
      transition: all .5s;
    }
    @include hover {
      &:before {
        opacity: .5;
      }
    }
  }
  &_control {
    display: flex;
    align-items: center;
    margin-top: 50px;
    @include respond-to(m) {
      justify-content: center;
    }
  }
  &_title {
    color: #282828;
    font-size: 21px;
    line-height: 32px;
    font-family: $FontMontserratSemiBold;
    margin-bottom: 44px;
    &:before {
      content: '';
      @include bg-svg($air-right);
      display: inline-block;
      vertical-align: middle;
      margin-right: 24px;
    }
  }
  &_status {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 600px;
    position: relative;
    margin-left: -100px;
    @include respond-to(t) {
      display: none;
    }
  }
  &_line {
    width: 130px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;

    &:before {
      content: '';
      @include position(centerY);
      width: 1200px;
      height: 1200px;
      right: 0;
      border-radius: 100%;
      border: 1px dashed #E0E0E0;
    }
  }
  &_pin {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    transform: translate(50%);
    position: relative;
    z-index: 2;
    cursor: pointer;
    &:nth-child(1) {
      margin-left: -79px;
    }
    &:nth-child(5) {
      margin-left: -79px;
    }
    &:nth-child(2) {
      margin-left: -19px;
    }
    &:nth-child(4) {
      margin-left: -19px;
    }
    &:after {
      @include position(center);
      content: '';
      width: 8px;
      height: 8px;
      z-index: -1;
      background: none;
      border-radius: 100%;
      transition: all .5s;
    }
    i {
      @include position(center);
      width: 8px;
      height: 8px;
      z-index: 1;
      border-radius: 100%;
      background: #D1C6EB;
    }
    &:before {
      @include position(center);
      content: '';
      width: 8px;
      height: 8px;
      border: 10px #fff solid;
      z-index: -1;
      background: none;
      border-radius: 100%;
      transition: all .5s;
    }
    &.active {
      background: #D1C5EB;
      &:before {
        width: 30px;
        height: 30px;
        background: #F6F3FB;
        border: 20px #fff solid;
      }
      &:after {
        width: 18px;
        height: 18px;
        background: #E2DBF3;
      }
    }
  }
}

.participants {
  display: flex;
  margin-top: 90px;
  margin-bottom: 50px;
  justify-content: space-between;
  @include respond-to(t) {
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  @include respond-to(m) {

  }
  &_text {
    font-size: 15px;
    line-height: 24px;
    color: #6C6C6C;
    margin-top: 24px;
    margin-bottom: 76px;
    @include respond-to(t) {
      margin-bottom: 520px;
    }
    @include respond-to(m) {
      margin-bottom: 360px;
    }
  }
  &_control {
    display: flex;
    align-items: center;
    .btn_link {
      margin-left: 54px;
      @include respond-to(m) {
        margin-top: 30px;
        margin-left: 0;
      }
    }
    @include respond-to(m) {
      flex-direction: column;
      align-items: center;
    }
  }

  &_form {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    @include respond-to(m) {
      flex-direction: column;
      align-items: center;
      margin-bottom: 10px;
    }
    .form-group {
      display: block;
      position: relative;
      margin-right: 39px;
      &.has-error {
        .help-block {
          opacity: 1;
          visibility: visible;
        }
      }
      &:last-child {
        margin-right: 0;
      }
      @include respond-to(m) {
        margin-right: 0;
        margin-bottom: 30px;
      }
    }
    .control-label {
      display: block;
      font-family: $FontMontserratSemiBold;
      color: #6C6C6C;
      font-size: 15px;
      margin-bottom: 9px;
    }
    .help-block {
      position: absolute;
      right: 0;
      top: 100%;
      font-size: 12px;
      color: #E94E71;
      margin-top: 4px;
      opacity: 0;
      visibility: hidden;
      transition: all .5s;
    }
    .form-control {
      display: block;
      width: 220px;
      height: 43px;
      background: rgba(237, 236, 241, 0.40);
      font-size: 15px;
      color: #282828;
      padding-left: 20px;
      border-radius: 6px;
      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #C0C0C0;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: #C0C0C0;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: #C0C0C0;
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: #C0C0C0;
      }

    }
    .select2 {
      &-hidden-accessible {
        display: none;
      }
      &-selection {
        display: flex;
        align-items: center;
        width: 220px;
        height: 43px;
        background: rgba(237, 236, 241, 0.40);
        font-size: 13px;
        padding-left: 20px;
        border-radius: 6px;
        color: #C0C0C0;
        justify-content: space-between;
      }
      &-search {
        display: none;
      }
      &-selection__arrow {
        display: block;
        @include bg-svg($bottom);
        margin-right: 18px;
      }
    }
    .select2_res {
      .select2-container {
        background: #fff;
        box-shadow: 0 14px 32px 0 rgba(113, 69, 203, 0.11);
        border-radius: 6px;
        font-size: 15px;
        color: #282828;
        line-height: 18px;
        padding: 14px 0;
        z-index: 999;
        top: 100% !important;
        left: 0 !important;
        width: 100%;
        max-height: 280px;
        overflow: auto;
      }
      .select2-results__option {
        padding: 9px 0;
        padding-left: 22px;
        cursor: pointer;
        @include hover {
          color: #5736AA;
        }
      }
    }

  }

}

.radar {
  width: 480px;
  height: 480px;
  border-radius: 100%;
  box-shadow: 0 8px 15px 0 rgba(113, 69, 203, 0.11);
  background: #5736AA;
  border: 12px #fff solid;
  margin-top: -30px;
  position: relative;
  @include respond-to(t) {
    position: absolute;
    top: 100px;
    margin-top: 0;
    left: 50%;
    margin-left: -240px;
  }
  @include respond-to(m) {
    width: 300px;
    height: 300px;
    left: 50%;
    margin-left: -150px;
    overflow: hidden;
    top: 120px;
  }
  &_h {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background: #9C83DC;
    pointer-events: none;
  }
  &_v {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    width: 1px;
    background: #9C83DC;
    pointer-events: none;
  }
  &_in {
    @include position(center);
    width: 50%;
    height: 50%;
    border: 1px #9C83DC solid;
    border-radius: 100%;
    pointer-events: none;
  }
  &_in2 {
    @include position(center);
    width: 75%;
    height: 75%;
    border: 1px #9C83DC solid;
    border-radius: 100%;
    pointer-events: none;
  }
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: url("../img/content/radar.png") center;
    background-size: cover;
    animation: radar 10s linear infinite;
  }
}

.map-all {
  &:before {
    content: '';
    @include position(center);
    background: url("../img/minified-svg/all.svg") center;
    background-size: cover;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    border-radius: 100%;
  }
}

.map-ua {
  &:before {
    content: '';
    @include position(center);
    background: url("../img/minified-svg/ua.svg") center;
    background-size: cover;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    border-radius: 100%;
  }
}

.map-eu {
  &:before {
    content: '';
    @include position(center);
    background: url("../img/minified-svg/europe.svg") center;
    background-size: cover;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    border-radius: 100%;
  }
}

.map-usa {
  &:before {
    content: '';
    @include position(center);
    background: url("../img/minified-svg/america.svg") center;
    background-size: cover;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    border-radius: 100%;
  }
}

.map-knr {
  &:before {
    content: '';
    @include position(center);
    background: url("../img/minified-svg/asia.svg") center;
    background-size: cover;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    border-radius: 100%;
  }
}

.radar_map {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 100%;
  display: none;
  &.active {
    display: block;
  }
}

@keyframes radar {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dot {
  position: absolute;
  &:hover {
    z-index: 40;
    .dot_content {
      opacity: 1;
      visibility: visible;

    }
  }
  i {
    @include position(center);
    width: 8px;
    height: 8px;
    z-index: 3;
    border-radius: 100%;
    background: #F9F6FF;
  }
  &:before {
    @include position(center);
    content: '';
    width: 18px;
    height: 18px;
    z-index: 2;
    background: #D1C6EB;
    border-radius: 100%;
    opacity: .54;
  }
  &:after {
    @include position(center);
    content: '';
    width: 30px;
    height: 30px;
    z-index: 1;
    background: #E7DDFF;
    border-radius: 100%;
    opacity: .2;
  }

  &_content {
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0 14px 32px 0 rgba(113, 69, 203, 0.11);
    border-radius: 4px;
    white-space: nowrap;
    padding-top: 20px;
    padding-left: 80px;
    padding-bottom: 15px;
    padding-right: 30px;
    left: -40px;
    top: -40px;
    visibility: hidden;
    opacity: 0;
    transition: all .5s;
    @include respond-to(m) {
      @include position(center);
      padding: 10px 20px;
      z-index: 2;
    }

  }
  &_title {
    font-size: 21px;
    color: #282828;
    font-family: $FontMontserratSemiBold;
    line-height: 32px;
  }
  &_text {
    color: #6C6C6C;
    font-size: 15px;
    line-height: 32px;
    opacity: 0.5;
  }

}

.table {
  //display: none;,
  .flex_between {
    align-items: baseline;
    @include respond-to(m) {
      display: block;
    }
  }
  &_title {
    color: #282828;
    font-size: 21px;
    font-family: $FontMontserratSemiBold;
    @include respond-to(m) {
      margin-bottom: 12px;
    }
  }
  &_count {
    color: #6C6C6C;
    font-size: 15px;
  }
  &_tb {
    display: table;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 90px;
  }
  &_tr {
    display: table-row;
    width: 100%;
    @include respond-to(m) {
      display: block;
      border-bottom: 1px #EEECF2 solid;
      position: relative;
      padding-left: 100px;
      padding-top: 30px;
      padding-bottom: 30px;
    }
    @include hover {
      .table {
        &_link {
          opacity: 1;
        }
        &_img {
          opacity: 1;
        }
        &_td {
          color: #000000;
          span {
            color: #000000;
          }
        }
      }
    }
  }
  &_td {
    border-bottom: 1px #EEECF2 solid;
    display: table-cell;
    height: 72px;
    vertical-align: middle;
    color: #282828;
    transition: all .5s;
    font-size: 15px;
    line-height: 24px;
    padding: 0 10px;
    span {
      color: #B5B5B5;
      transition: all .5s;
    }
    @include respond-to(m) {
      display: block;
      border-bottom: none;
      height: auto;
    }
  }
  &_img {
    opacity: 0.5;
    display: block;
    margin: auto;
    max-height: 50px;
    transition: all .5s;
    max-width: 150px;
    @include respond-to(m) {
      position: absolute;
      left: 0;
      top: 30px;
      max-width: 90px;
    }
  }
  &_link {
    @include bg-svg($arrow_right2);
    display: block;
    opacity: 0.5;
    transition: all .5s;
    @include respond-to(m) {
      position: absolute;
      right: 0;
      bottom: 35px;
    }
  }
}

.stop {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &_title {
    font-family: $FontMontserratSemiBold;
    font-size: 16px;
    line-height: 32px;
    color: #282828;
  }

  &_item {
    display: flex;
    align-items: center;
    margin-top: 50px;
  }
  &_image {
    width: 120px;
    text-align: center;
    font-size: 15px;
    color: #E0E0E0;
    font-family: $FontRobotoBold;
    @include respond-to(m) {
      width: 90px;
      min-width: 90px;
      img {
        width: 90px;
      }
    }
  }
  &_img {
    display: block;
    margin: auto;
  }
  &_text {
    color: #9B9B9B;
    font-size: 15px;
    line-height: 24px;
    margin-left: 20px;
    width: 300px;
    min-width: 300px;
    @include respond-to(m) {
      width: auto;
      min-width: 50px;
    }

  }
}

.first-news {
  display: flex;
  align-items: center;
  @include respond-to(m) {
    flex-direction: column-reverse;
    margin-top: 30px;
    .home_top {
      display: none;
    }

  }
  &_text {
    margin: 50px 0;
    @include respond-to(t) {
      margin: 25px 0;
    }
  }
  &_content {
    margin-right: 70px;
    @include respond-to(t) {
      margin-right: 30px;
      padding: 50px 0;
    }
    @include respond-to(m) {
      margin-right: 0;
    }
  }
  &_image {
    margin-right: -120px;
    @include respond-to(t) {
      margin-right: 0;
    }
    @include respond-to(m) {
      margin-right: 0;
    }

  }

}

.horizontal {
  position: relative;
  display: inline-block;
  svg {
    display: block;
    width: 617px;
    @include respond-to(t) {
      width: 308px;
    }
  }
  &_wrap {
    position: absolute;

    z-index: 1;
    //width: 100%;
    //height: 100%;
    //padding: 2px;
    @include position(centerX);
    top: 30px;
    border-radius: 42%;
    overflow: hidden;
    @include respond-to(t) {
      top: 15px;
    }
    svg {

      z-index: 1;
      width: 527px;
      position: relative;
      @include respond-to(t) {
        width: 263px;
      }
    }
  }

  &_img {
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background-size: cover;
    border-radius: 42%;
    position: absolute;
    top: 2px;
    left: 2px;
  }
}

.news-filter {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
  @include respond-to(t) {
    display: block;
    margin-bottom: 42px;
  }
}

.news-sort {
  position: relative;
  display: flex;
  font-size: 15px;
  &_list {
    position: absolute;
    top: 100%;
    z-index: 2;
    background: #FFFFFF;
    box-shadow: 0 14px 32px 0 rgba(113, 69, 203, 0.11);
    padding: 30px 0 20px;
    right: 0;
    width: auto;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: all .2s;
    @include respond-to(t) {
      left: 0;
      width: 100%;
    }
    &.active {
      visibility: visible;
      opacity: 1;

    }
  }

  &_val {
    color: #9B9B9B;
    display: flex;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
    &:after {
      content: '';
      display: block;
      background: rgba(87, 54, 170, 0.30);
      width: 5px;
      height: 5px;
      border-radius: 100%;
      margin-left: 10px;
    }
  }
  &_link {
    font-size: 15px;
    color: #282828;
    display: block;
    line-height: 34px;
    padding: 0 35px;
    @include hover {
      color: #5736AA;
    }
  }
}

.news-tab {
  line-height: 70px;
  font-size: 21px;

  @include respond-to(m) {
    margin-bottom: 30px;

  }
  &_link {
    color: #282828;
    opacity: .3;
    margin-right: 60px;
    font-family: $FontMontserratSemiBold;
    @include respond-to(m) {
      display: block;
      line-height: 50px;
    }
    &.active {
      opacity: 1;
    }
  }
}

.news-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 100px;
  @include respond-to(t) {
    justify-content: space-around;
  }
  .news-card {
    margin-bottom: 40px;

  }
}

.archive {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 40px;
  @include respond-to(t) {
    width: 320px;
  }
  @include respond-to(m) {
    width: 100%;
    justify-content: flex-start;
  }
  &_title {
    font-size: 21px;
    font-family: $FontMontserratSemiBold;
    line-height: 70px;
    text-align: right;
    @include respond-to(m) {
      text-align: left;
    }

  }
  &_list {
    display: flex;
    margin-top: 40px;
    flex-wrap: wrap;
    @include respond-to(m) {
      margin-top: 10px;
    }
  }
  &_col {

  }
  &_link {
    width: 50%;
    display: block;
    text-align: right;
    font-size: 15px;
    line-height: 48px;
    color: #282828;
    font-family: $FontRobotoMedium;
    @include hover {
      color: #5736AA;
    }
    @include respond-to(t) {
      width: 33%;
    }
    @include respond-to(m) {
      text-align: left;
    }
  }
}

.pagination {
  display: flex;
  align-items: center;
  color: #DDD9E6;
  font-size: 24px;
  word-spacing: -1.6px;;
  &_link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border: 1px transparent solid;
    border-radius: 100%;
    color: #DDD9E6;
    font-size: 14px;
    font-family: $FontMontserratSemiBold;
    &.active {
      border: 1px #DBD2F1 solid;
      color: #5736AA;
    }
    @include hover {
      color: #5736AA;
    }
  }
  li {
    list-style: none;
  }
  li.active {
    a {
      border: 1px #DBD2F1 solid;
      color: #5736AA;
    }
  }
  //&_prev {
  //  transform: rotate(180deg);
  //}
  &_prev {
    margin-right: 14px;
  }
  &_next {
    margin-left: 14px;
  }
  &_prev, &_next {
    border: 1px #DBD2F1 solid;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    position: relative;

    .arrow-right {
      opacity: 0.5;
      margin: 0;
      @include position(center);
      @include bg-svg($arrow_right2);

    }

    .arrow-left {
      opacity: 0.5;
      margin: 0;
      @include position(center);
      @include bg-svg($arrow_left2);

    }
    @include hover {
      .arrow-right, .arrow-left {
        opacity: 1;
      }
    }
  }
  @include respond-to(t) {
    justify-content: center;
  }
  &.mob {
    display: none;
  }
  @include respond-to(m) {
    display: none;
    &.mob {
      display: flex;
      margin-bottom: 20px;
    }
  }
}

.more-news {
  margin-top: 150px;
  display: flex;
  justify-content: space-between;
  @include respond-to(t) {
    flex-direction: column-reverse;
  }
  &_flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include respond-to(t) {
      justify-content: space-around;
    }
  }
  &_wrap {
    width: 66%;
    min-width: 66%;
    @include respond-to(t) {
      width: 100%;
      min-width: 100%;
    }
    .news-card_min {
      margin-bottom: 20px;
      margin-left: -30px;
      @include respond-to(m) {
        margin-left: 0;
        .news-card_title {
          padding-left: 0;
          padding-right: 0;
        }
        .news-card_time {
          left: 0;
        }
        .news-card_view {
          right: 0;
        }
      }
    }
  }
  &_control {
    .title {
      text-align: right;
      @include respond-to(t) {
        margin-bottom: 30px;
        text-align: center;
      }
    }
  }
  &_btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 70px;
    &.mob {
      display: none;
    }
    @include respond-to(t) {
      display: none;
      &.mob {
        display: flex;
        justify-content: center;
        margin-top: 30px;
      }
    }
  }
  //&_prev {
  //  transform: rotate(180deg);
  //}
  &_prev, &_next {
    border: 1px #DBD2F1 solid;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    position: relative;
    margin-left: 14px;
    font-size: 0;
    a {
      display: block;
      width: 100%;
      height: 100%;
    }
    .arrow-right {
      opacity: 0.5;
      margin: 0;
      @include position(center);
      @include bg-svg($arrow_right2);
      pointer-events: none;

    }
    .arrow-left {
      opacity: 0.5;
      margin: 0;
      @include position(center);
      @include bg-svg($arrow_left2);
      pointer-events: none;

    }
    @include hover {
      .arrow-right, .arrow-left {
        opacity: 1;
      }
    }
    @include respond-to(m) {
      margin: 0 10px;
    }
  }
  .bx-viewport {
    overflow: visible !important;
  }
}

.article {
  &_info {
    position: relative;
    font-size: 15px;
    color: #B5B5B5;
    margin-top: 30px;
    margin-bottom: 40px;
    height: 20px;
    .news-card_time {
      top: 0;
      left: 0;
    }
    .news-card_view {
      top: 0;
      left: 300px;
      @include respond-to(m) {
        left: auto;
        right: 0;
      }
    }
  }

  &_control {
    display: flex;
    align-items: center;
    margin-top: 43px;
    margin-bottom: 115px;
    @include respond-to(m) {
      justify-content: center;
      margin-bottom: 70px;
    }
  }

  &_btn {
    position: relative;
    cursor: pointer;
    margin-left: -10px;
    &:before {
      content: '';
      @include position(center);
      width: 3px;
      height: 3px;
      background: #DBD2F1;
      border-radius: 100%;
    }
    &.active {
      .article {
        &_index {
          transform: scale(1);
          svg {
            transition: opacity .1s, stroke-dashoffset 5s .5s;
            opacity: 1;
            stroke-dashoffset: -251px;
          }
        }
      }
    }
  }
  &_index {
    width: 42px;
    min-width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5736AA;
    cursor: pointer;
    font-size: 14px;
    font-family: $FontMontserratSemiBold;
    border: 1px #DBD2F1 solid;
    border-radius: 100%;
    transform: scale(0);
    background: #fff;
    transition: all .5s;

    svg {
      position: absolute;
      top: -2px;
      left: -2px;
      width: 44px;
      height: 44px;;

      stroke-dasharray: 126px;
      stroke-dashoffset: -126px;
      transform: rotate(-90deg);
      transition: opacity .3s, stroke-dashoffset 0s .3s;
      opacity: 0;
    }
  }
  &_next {
    width: 42px;
    min-width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 100%;
    background: #EAE7F1;
    transform: rotate(-90deg);
    margin-left: 10px;
    &:before {
      content: '';
      @include bg-svg($air-bottom);
      opacity: 0.1;
      transition: all .5s;
    }
    @include hover {
      &:before {
        opacity: .5;
      }
    }
  }
  &_slider {
    display: flex;
    @include respond-to(m) {
      display: block;
    }
  }

  &_slider_wrap {
    width: 100%;
  }

  &_slide {
    .bx-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

  }
  &_slider-js {
    width: 100%;
    height: 100%;
    @include respond-to(m) {
      text-align: center;
    }
  }
  &_controls {
    display: flex;
    justify-content: space-between;
  }
  &_btn-link {
    display: flex;
    justify-content: flex-end;
  }
  //&_prev {
  //  transform: rotate(180deg);
  //}
  &_prev-link, &_next-link {
    border: 1px #DBD2F1 solid;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    position: relative;
    margin-left: 14px;
    font-size: 0;
    .arrow-right {
      opacity: 0.5;
      margin: 0;
      @include position(center);
      @include bg-svg($arrow_right2);
      pointer-events: none;

    }
    .arrow-left {
      opacity: 0.5;
      margin: 0;
      @include position(center);
      @include bg-svg($arrow_left2);
      pointer-events: none;

    }
    @include hover {
      .arrow-right, .arrow-left {
        opacity: 1;
      }
    }
    @include respond-to(m) {
      margin: 0 10px;
    }
  }
}

.article-more {
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  .btn_text {
    @include respond-to(m) {
      justify-content: center;
    }
  }
  @include respond-to(t) {
    display: block;
  }
  &_news {
    .news-card {
      margin-right: 11px;
      @include respond-to(m) {
        margin: auto;
        margin-bottom: 40px;
      }

    }
  }
  &_news-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 70px;
    margin-top: 40px;
    @include respond-to(t) {
      justify-content: flex-start;
    }
    @include respond-to(m) {
      display: block;
    }
  }
  &_blog {

    @include respond-to(t) {
      margin: auto;
      margin-top: 70px;
    }
    .title_min {
      margin-bottom: 15px;
    }
    .btn_text {
      margin-top: 30px;
    }
    .news-card_min {
      margin-left: -30px;
      @include respond-to(t) {
        margin-left: 0;
        display: inline-block;
      }
      @include hover {
        z-index: 2;
      }
      @include respond-to(m) {
        margin-left: 0;
        width: 100%;
        .news-card_title {
          padding-left: 0;
          padding-right: 0;
        }
        .news-card_time {
          left: 0;
        }
        .news-card_view {
          right: 0;
        }
      }

    }
  }
}

.membership {
  &_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 80px;
    @include respond-to(m) {
      margin-top: 30px;
      width: 100%;
      overflow: hidden;
    }
    .horizontal {
      margin-left: -50px;
      @include respond-to(t) {
        margin-left: 0;
      }
      @include respond-to(m) {
        margin-left: auto;
        margin-right: auto;
      }
    }
    .title_min {
      margin-bottom: 50px;
      @include respond-to(m) {
        margin-bottom: 30px;
        margin-top: 20px;
      }
    }
    @include respond-to(m) {
      display: block;
    }
  }
  &_right {
    margin-left: 50px;
    @include respond-to(m) {
      margin-left: 0;
    }
  }
}

.stage {
  margin-top: 130px;
  .title {
    text-align: center;
    margin-bottom: 100px;
  }
  &_wrap {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin: 0 -60px;
    @include respond-to(t) {
      margin: 0;
    }
    @include respond-to(m) {
      display: block;
    }
  }
  &_item {
    width: 230px;
    @include respond-to(m) {
      width: 100%;
      height: 200px;
      &:nth-child(odd) {
        padding-left: 50%;
      }
      &:nth-child(even) {
        padding-right: 50%;
      }
    }

  }
  &_text {
    font-family: $FontRobotoMedium;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
  }
  &_number {
    background-image: linear-gradient(-135deg, #5235AB 0%, #9044A0 100%);
    box-shadow: 0 8px 9px 0 rgba(120, 62, 156, 0.21), 0 8px 15px 0 rgba(120, 62, 156, 0.12);
    border-radius: 100%;
    width: 55px;
    height: 55px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 60px;
    @include respond-to(m) {
      margin-bottom: 30px;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      background-image: linear-gradient(-196deg, #5235AB 0%, #9044A0 100%);
      transition: all .5s;
    }
    span {
      position: relative;
      z-index: 2;
      color: #fff;
      font-family: $FontMontserratSemiBold;
      font-size: 14px;
    }
    @include hover {
      &:before {
        opacity: 0;
      }
    }
  }
  &_doted-c {
    position: absolute;
    height: 100px;
    left: 40%;
    width: 20%;
    top: -20px;
    background: url("../img/general/doted.png") center bottom no-repeat;
    //overflow: hidden;
    @include respond-to(m) {
      width: 220px;
      transform: rotate(124deg);
      top: 290px;
      left: 49px;
    }
    svg {
      position: absolute;
      transform: translate(-100%, -15px) rotate(-70deg);
      bottom: 0;
      opacity: 0;
      //transition: all 2s;
      @include respond-to(d) {
        animation: air 12s infinite linear;
        animation-delay: 4s;
      }
    }

    &.left {
      left: 150px;
      transform: scaleY(-1);
      @include respond-to(t) {
        left: 15%;
      }
      @include respond-to(m) {
        width: 220px;
        transform: rotate(-124deg);
        top: 80px;
        left: 19px;
      }
      @include respond-to(d) {
        svg {
          animation: air 12s infinite linear;
          animation-delay: 0s;
        }
      }
    }
    &.right {
      left: auto;
      right: 150px;
      transform: scaleY(-1);
      @include respond-to(m) {
        width: 220px;
        transform: rotate(-124deg);
        top: 500px;
        left: 19px;
      }
      @include respond-to(t) {
        right: 15%;
      }
      @include respond-to(d) {
        svg {
          animation: air 12s infinite linear;
          animation-delay: 8s;
        }
      }
    }
  }
}

@keyframes air {
  0% {
    opacity: 0;
    transform: translate(-100%, -15px) rotate(-70deg);
  }
  66% {
    opacity: 0;
    transform: translate(-100%, -15px) rotate(-70deg);
  }

  74% {
    opacity: 1;
    transform: translate(325%, 5px) rotate(-80deg)
  }
  82% {
    opacity: 1;
    transform: translate(650%, 10px) rotate(-90deg);
  }
  91% {
    opacity: 1;
    transform: translate(975%, 5px) rotate(-100deg)
  }
  100% {
    opacity: 0;
    transform: translate(1400%, -10px) rotate(-110deg);
  }
}

.done_massage {
  display: none;
}

.done_massage_text {

  position: absolute;
  transform: translate(0, -50%);
  left: 0;
  top: 50%;
  line-height: 24px;
}

.has-done {
  .member_wrap {
    height: 250px;
    @include respond-to(m) {
      height: 400px;
    }
    &:after {
      opacity: 1
    }
  }
  .member_form {
    display: none;
  }
  .informed_form {
    display: none;
  }
  .done_massage {
    display: block;
    animation: fadeIn 0.5s;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }

}

.done_massage_m {
  @include respond-to(t) {
    margin-top: 50px;
  }
}

.bx-slide-main_wrap {
  margin-bottom: 24px;
  .bx-controls-direction {
    display: flex;
    margin-top: 20px;
    justify-content: center;
  }
  .bx-prev {
    &:before {
      content: '';
      opacity: 0.5;
      margin: 0;
      @include position(center);
      @include bg-svg($arrow_left2);
      pointer-events: none;

    }
  }
  .bx-next {
    &:before {
      content: '';
      opacity: 0.5;
      margin: 0;
      @include position(center);
      @include bg-svg($arrow_right2);
      pointer-events: none;
    }

  }
  .bx-prev, .bx-next {
    border: 1px #DBD2F1 solid;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    position: relative;
    margin-left: 14px;
    font-size: 0;
    .arrow-right {
      opacity: 0.5;
      margin: 0;
      @include position(center);
      @include bg-svg($arrow_right2);
      pointer-events: none;

    }
    .arrow-left {
      opacity: 0.5;
      margin: 0;
      @include position(center);
      @include bg-svg($arrow_left2);
      pointer-events: none;

    }
    @include hover {
      &:before {
        opacity: 1;
      }
    }
    @include respond-to(m) {
      margin: 0 10px;
    }
  }

  img {
    width: auto !important;
    max-height: 380px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    left: 50%;
    transform: translateX(-50%);
  }
}

.error-page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: -150px;
  background: url("../img/general/404.png") center no-repeat;
  background-size: contain;
  .radar {
    @include respond-to(dm) {
      width: 250px;
      height: 250px;
    }
    @include respond-to(t) {
      width: 200px;
      height: 200px;
      position: relative;
      left: 0;
      top: 0;
      margin: 0;
    }
    @include respond-to(m) {
      width: 100px;
      height: 100px;
      position: relative;
      left: 0;
      top: 0;
      margin: 0;

    }
  }
  &_title {
    font-size: 270px;
    display: flex;
    color: #5838AA;
    font-family: $FontMontserratExtraBold;
    align-items: center;
    @include respond-to(m) {
      font-size: 100px;
    }
  }
  &_text {
    font-family: $FontMontserratSemiBold;
    font-size: 21px;
    margin-top: 40px;
  }
}

