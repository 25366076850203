html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video, input, textarea, button {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  outline: none;
  border-radius: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-appearance: none;
  -webkit-overflow-scrolling: touch;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a:hover, a:active {
  outline: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Your custom fonts here */
/* Example

@font-face {
    font-family: 'CustomFont';
    src: url('../fonts/Custom-Font.eot');
    src: url('../fonts/Custom-Font.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Custom-Font.woff') format('woff'),
         url('../fonts/Custom-Font.svg#custom_font') format('svg');
    font-weight: 400;       // For normal width. It could has another value   
    font-style: normal;     // Also could has another value
    }


// Var for using custom font
$CustomFont: 'CustomFont', Helvetica, Arial, sans-serif;
*/
@font-face {
  font-family: 'Roboto Regular';
  src: url("../fonts/Roboto-Regular.eot");
  src: url("../fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Regular.woff") format("woff"), url("../fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Roboto Medium';
  src: url("../fonts/Roboto-Medium.eot");
  src: url("../fonts/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Medium.woff") format("woff"), url("../fonts/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: 'Roboto Bold';
  src: url("../fonts/Roboto-Bold.eot");
  src: url("../fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Bold.woff") format("woff"), url("../fonts/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: 'Montserrat SemiBold';
  src: url("../fonts/Montserrat-SemiBold.eot");
  src: url("../fonts/Montserrat-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-SemiBold.woff") format("woff"), url("../fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: 'Montserrat ExtraBold';
  src: url("../fonts/Montserrat-ExtraBold.eot");
  src: url("../fonts/Montserrat-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-ExtraBold.woff") format("woff"), url("../fonts/Montserrat-ExtraBold.ttf") format("truetype");
}

.h1, h1 {
  font-size: 50px;
  font-family: "Montserrat SemiBold", Helvetica, Arial, sans-serif;
  line-height: 70px;
  color: #282828;
  position: relative;
  word-wrap: break-word;
}

.h1_abs, h1_abs {
  position: absolute;
  bottom: 100%;
  font-size: 18px;
  line-height: 45px;
}

@media only screen and (max-width: 1200px) {
  .h1_abs, h1_abs {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
  }
}

@media only screen and (max-width: 1200px) {
  .h1, h1 {
    font-size: 30px;
    line-height: 45px;
  }
}

.title, h2 {
  font-size: 36px;
  font-family: "Montserrat SemiBold", Helvetica, Arial, sans-serif;
  line-height: 48px;
  color: #282828;
}

.title.center, h2.center {
  text-align: center;
}

@media only screen and (max-width: 1200px) {
  .title, h2 {
    font-size: 30px;
    line-height: 37px;
  }
}

.title_min, h3 {
  font-size: 21px;
  font-family: "Montserrat SemiBold", Helvetica, Arial, sans-serif;
  line-height: 36px;
  color: #282828;
}

.title_min.center, h3.center {
  text-align: center;
}

h4 {
  font-size: 15px;
  font-family: "Montserrat SemiBold", Helvetica, Arial, sans-serif;
  line-height: 24px;
  color: #282828;
}

h5 {
  font-size: 13px;
  font-family: "Montserrat SemiBold", Helvetica, Arial, sans-serif;
  line-height: 18px;
  color: #282828;
}

p {
  font-size: 15px;
  line-height: 24px;
  color: #6C6C6C;
}

.btn {
  box-shadow: 0 8px 9px 0 rgba(120, 62, 156, 0.21), 0 8px 15px 0 rgba(120, 62, 156, 0.12);
  border-radius: 100px;
  height: 45px;
  width: 207px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto Bold", Helvetica, Arial, sans-serif;
  font-size: 15px;
  color: #fff;
  transition: all .5s;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: none;
  text-align: center;
}

.btn:after, .btn:before {
  content: '';
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(-135deg, #5235AB 0%, #9044A0 100%);
  z-index: -1;
  transition: all .5s;
}

.btn:before {
  background-image: linear-gradient(-196deg, #5235AB 0%, #9044A0 100%);
}

@media only screen and (min-width: 1201px) {
  .btn:hover {
    box-shadow: 0 8px 9px 0 rgba(120, 62, 156, 0.21), 0 8px 15px 0 rgba(120, 62, 156, 0.12);
  }
  .btn:hover:after {
    opacity: 0;
  }
}

.btn_min {
  cursor: pointer;
  width: auto;
  padding: 0 45px;
}

.btn_text {
  font-size: 16px;
  font-family: "Montserrat SemiBold", Helvetica, Arial, sans-serif;
  color: #282828;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.btn_text .arrow-right {
  content: '';
  background-image: url("../img/svg-sprite/sprite.svg");
  background-position: -4px -2167px;
  width: 18px;
  height: 14px;
  background-repeat: no-repeat;
  margin-left: 15px;
  display: inline-block;
}

.btn_text .arrow-left {
  content: '';
  background-image: url("../img/svg-sprite/sprite.svg");
  background-position: -4px -2263px;
  width: 16px;
  height: 13px;
  background-repeat: no-repeat;
  margin-right: 15px;
  display: inline-block;
}

.btn_text .btn_plus {
  margin-right: 15px;
}

.btn_link {
  font-size: 14px;
  font-family: "Roboto Medium", Helvetica, Arial, sans-serif;
  color: #282828;
  cursor: pointer;
  line-height: 16px;
}

.btn_plus {
  background-image: linear-gradient(-135deg, #5235AB 0%, #9044A0 100%);
  box-shadow: 0 8px 9px 0 rgba(120, 62, 156, 0.21), 0 8px 15px 0 rgba(120, 62, 156, 0.12);
  border-radius: 100%;
  width: 45px;
  height: 45px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.btn_plus:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-image: linear-gradient(-196deg, #5235AB 0%, #9044A0 100%);
  transition: all .5s;
}

.btn_plus:after {
  content: '+';
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  color: #fff;
  font-size: 18px;
  line-height: 18px;
  font-family: "Montserrat SemiBold", Helvetica, Arial, sans-serif;
  text-align: center;
  width: 100%;
  letter-spacing: 0;
}

@media only screen and (min-width: 1201px) {
  .btn_plus:hover:before {
    opacity: 0;
  }
}

.btn_plus.active:after {
  content: '-';
  box-sizing: content-box;
  margin-top: -1px;
}

.btn_download {
  background-image: linear-gradient(-135deg, #5235AB 0%, #9044A0 100%);
  box-shadow: 0 8px 9px 0 rgba(120, 62, 156, 0.21), 0 8px 15px 0 rgba(120, 62, 156, 0.12);
  border-radius: 100%;
  width: 67px;
  height: 67px;
  position: relative;
  cursor: pointer;
}

.btn_download:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-image: linear-gradient(-196deg, #5235AB 0%, #9044A0 100%);
  transition: all .5s;
}

.btn_download:after {
  content: '';
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background-image: url("../img/svg-sprite/sprite.svg");
  background-position: -4px -2125px;
  width: 12px;
  height: 22px;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 1201px) {
  .btn_download:hover:before {
    opacity: 0;
  }
}

.btn_download_loader {
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  z-index: 2;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
}

.btn_download_loader svg {
  width: 100%;
  height: 100%;
  color: transparent;
  stroke-dasharray: 215px;
  stroke-dashoffset: -215px;
  transform: rotate(-90deg);
  transition: opacity .3s, stroke-dashoffset 0s .3s;
  opacity: 0;
}

.btn_download_loader.active svg {
  transition: opacity .1s, stroke-dashoffset 1s;
  opacity: 1;
  stroke-dashoffset: -429px;
}

.btn_right {
  background-image: linear-gradient(-135deg, #5235AB 0%, #9044A0 100%);
  box-shadow: 0 8px 9px 0 rgba(120, 62, 156, 0.21), 0 8px 15px 0 rgba(120, 62, 156, 0.12);
  border-radius: 100%;
  width: 67px;
  height: 67px;
  position: relative;
  cursor: pointer;
}

.btn_right:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-image: linear-gradient(-196deg, #5235AB 0%, #9044A0 100%);
  transition: all .5s;
}

_wrap
.btn_right:after {
  content: '';
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background-image: url("../img/svg-sprite/sprite.svg");
  background-position: -4px -2320px;
  width: 14px;
  height: 12px;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 1201px) {
  .btn_right:hover:before {
    opacity: 0;
  }
}

button.btn {
  display: block;
}

@media only screen and (max-width: 1200px) {
  .center-mob {
    text-align: center;
  }
}

.news-card {
  width: 320px;
  height: 360px;
  display: block;
  position: relative;
  background: #FFFFFF;
  box-shadow: 0 3px 20px 0 rgba(113, 69, 203, 0.08);
  border-radius: 4px;
  transition: all .5s;
  font-size: 15px;
  color: #B5B5B5;
}

@media only screen and (min-width: 1201px) {
  .news-card:hover {
    box-shadow: 0 14px 32px 0 rgba(113, 69, 203, 0.11);
  }
}

@media only screen and (max-width: 750px) {
  .news-card {
    width: 290px;
  }
}

.news-card_image {
  width: 100%;
  height: 196px;
  background-size: cover;
  display: block;
  background-position: center;
}

.news-card_title {
  padding: 20px 30px;
  font-family: "Roboto Medium", Helvetica, Arial, sans-serif;
  font-size: 18px;
  color: #282828;
  line-height: 27px;
  height: 100px;
  overflow: hidden;
}

.news-card_time {
  position: absolute;
  left: 30px;
  bottom: 25px;
}

.news-card_view {
  position: absolute;
  right: 30px;
  bottom: 25px;
}

.news-card_view:before {
  content: '';
  display: inline-block;
  background-image: url("../img/svg-sprite/sprite.svg");
  background-position: -4px -2151px;
  width: 19px;
  height: 12px;
  background-repeat: no-repeat;
  margin-right: 5px;
  vertical-align: middle;
}

.news-card_big {
  width: 650px;
}

@media only screen and (max-width: 1200px) {
  .news-card_big {
    width: 320px;
  }
}

.news-card_min {
  box-shadow: none;
  padding: 0;
  height: 140px;
}

article {
  width: 580px;
  margin: auto;
  margin-top: 45px;
}

@media only screen and (max-width: 750px) {
  article {
    width: 100%;
  }
}

article h3, article h4, article h5 {
  margin-bottom: 24px;
  margin-top: 32px;
}

article p {
  margin-bottom: 24px;
}

article strong {
  color: #282828;
  font-family: "Roboto Medium", Helvetica, Arial, sans-serif;
}

article ul {
  margin-top: 24px;
}

article ul > li {
  font-size: 15px;
  line-height: 24px;
  color: #6C6C6C;
  margin-bottom: 24px;
  position: relative;
  padding-left: 25px;
}

article ul > li:before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #D1C6EB;
  border-radius: 100%;
  top: 10px;
  left: 0;
  position: absolute;
}

article ol {
  margin-top: 24px;
}

article ol > li {
  font-size: 15px;
  line-height: 24px;
  color: #6C6C6C;
  margin-bottom: 24px;
  position: relative;
  padding-left: 35px;
  counter-increment: section;
}

article ol > li:before {
  content: "0" counter(section);
}

article ol > li:nth-child(n+10):before {
  content: counter(section);
}

article ol > li:before {
  display: inline-block;
  top: 0px;
  left: 0;
  position: absolute;
  color: #282828;
  font-size: 14px;
  font-family: "Montserrat SemiBold", Helvetica, Arial, sans-serif;
}

article img {
  width: 100%;
  margin-bottom: 24px;
}

article blockquote {
  margin-bottom: 24px;
}

article blockquote .autor {
  margin-top: 12px;
  text-align: right;
}

html, body {
  width: 100%;
  height: 100%;
  font-family: "Roboto Regular", Helvetica, Arial, sans-serif;
  color: #282828;
}

html.hidden, body.hidden {
  overflow: hidden;
}

.wrapper {
  width: 980px;
  margin: auto;
}

@media only screen and (max-width: 1200px) {
  .wrapper {
    width: 100%;
    padding: 0 20px;
  }
}

@media only screen and (max-width: 750px) {
  .wrapper {
    padding: 0 15px;
  }
}

.wrapper_min {
  width: 850px;
  margin: auto;
}

.flex {
  display: flex;
}

.flex_between {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.footer {
  background: #282828;
  color: #fff;
  padding-top: 80px;
}

.footer_a {
  display: block;
  color: rgba(255, 255, 255, 0.3);
}

.footer_text {
  color: rgba(255, 255, 255, 0.3);
  font-size: 13px;
  line-height: 24px;
  margin-left: 60px;
}

.footer_text:first-child {
  margin-left: 0;
}

@media only screen and (max-width: 750px) {
  .footer_text {
    margin-left: 0;
    margin-bottom: 24px;
  }
}

.footer_white {
  font-family: "Roboto Bold", Helvetica, Arial, sans-serif;
  font-size: 15px;
  color: #fff;
  display: block;
  line-height: 40px;
}

.footer_bottom {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  margin-top: 10px;
}

@media only screen and (max-width: 1200px) {
  .footer_bottom {
    padding: 20px;
  }
}

@media only screen and (max-width: 750px) {
  .footer_bottom {
    flex-direction: column-reverse;
    padding-bottom: 50px;
    height: auto;
    margin-top: 0;
  }
}

@media only screen and (max-width: 750px) {
  .footer .flex_between {
    display: block;
    text-align: center;
  }
  .footer_contact {
    margin-top: 24px;
  }
}

.copyright {
  opacity: 0.5;
  font-size: 13px;
}

.facility {
  width: 130px;
}

@media only screen and (max-width: 750px) {
  .facility {
    margin-bottom: 24px;
  }
}

.ico {
  width: 32px;
  height: 32px;
  border: 1px #fff solid;
  border-radius: 100%;
  display: block;
  position: relative;
}

@media only screen and (max-width: 750px) {
  .ico {
    margin: auto;
  }
}

.ico:before {
  content: '';
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.ico_fb:before {
  background-image: url("../img/svg-sprite/sprite.svg");
  background-position: -4px -2280px;
  width: 7px;
  height: 16px;
  background-repeat: no-repeat;
}

.header {
  display: flex;
  align-items: center;
  padding: 15px 50px;
  margin-bottom: 50px;
}

@media only screen and (max-width: 1200px) {
  .header {
    justify-content: space-between;
    padding: 15px 20px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 750px) {
  .header {
    padding: 15px 15px;
  }
}

.header .logo {
  height: 70px;
}

.header .logo svg {
  height: 100%;
  display: block;
}

.header_mob {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .header_mob {
    display: flex;
    opacity: 0;
  }
}

.menu_btn {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .menu_btn {
    cursor: pointer;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 45px;
    height: 45px;
    background: #FFFFFF;
    box-shadow: 0 8px 9px 0 rgba(120, 62, 156, 0.21), 0 8px 15px 0 rgba(120, 62, 156, 0.12);
    border-radius: 100px;
  }
  .menu_btn i {
    display: block;
    width: 19px;
    height: 2px;
    background: #5736AA;
    margin-bottom: 3px;
  }
  .menu_btn i:last-child {
    margin-bottom: 0;
  }
  .menu_btn span {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 19px;
    height: 2px;
    display: block;
    background: #5736AA;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .menu_btn span:last-child {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.menu_mob_wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .menu {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: scroll;
    text-align: center;
    z-index: 99;
    padding-bottom: 50px;
    visibility: hidden;
    transition: all .5s .5s;
  }
  .menu_mob_wrapper {
    display: block;
    position: relative;
  }
  .menu_mob_wrapper:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    right: 40px;
    bottom: calc(100% - 50px);
    transform: translateX(50%);
    box-shadow: 0 8px 9px 0 rgba(120, 62, 156, 0.21), 0 8px 15px 0 rgba(120, 62, 156, 0.12);
    border-radius: 100%;
    transition: all 0.5s ease-out;
    background: #fff;
    z-index: -1;
  }
  .menu .menu_btn {
    box-shadow: none;
  }
  .menu.active {
    visibility: visible;
    transition: all .5s;
  }
  .menu.active .header_mob {
    opacity: 1;
  }
  .menu.active .menu_mob_wrapper {
    overflow: hidden;
  }
  .menu.active .menu_mob_wrapper:before {
    bottom: 20px;
    right: 50%;
    width: calc(200vh - 50px);
    height: calc(200vh - 50px);
  }
}

@media only screen and (max-width: 1200px) and (max-width: 1200px) and (orientation: landscape) {
  .menu.active .menu_mob_wrapper:before {
    width: calc(200vw - 50px);
    height: calc(200vw - 50px);
  }
}

@media only screen and (max-width: 1200px) {
  .menu.active .menu_lang {
    opacity: 1;
    transition: all .5s 0.2s;
  }
  .menu.active .menu_site {
    opacity: 1;
    transition: all .5s 0.2s;
  }
  .menu.active .menu_contact {
    opacity: 1;
    transition: all .5s 0.2s;
  }
}

.menu_lang {
  display: flex;
  align-items: center;
  margin-left: 50px;
}

@media only screen and (max-width: 1200px) {
  .menu_lang {
    justify-content: center;
    margin-left: 0;
    margin-bottom: 50px;
    transition: all .5s;
    opacity: 0;
  }
}

.menu_lang_li {
  margin-right: 15px;
}

.menu_lang_li:last-child {
  margin-right: 0;
}

.menu_lang_li.active .menu_lang_a {
  color: #C0C0C0;
}

.menu_lang_a {
  color: #282828;
  font-size: 15px;
  font-family: "Roboto Medium", Helvetica, Arial, sans-serif;
}

.menu_site {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1200px) {
  .menu_site {
    display: block;
    margin-bottom: 50px;
    transition: all .5s;
    opacity: 0;
  }
}

.menu_site_li {
  margin-left: 55px;
  position: relative;
}

.menu_site_li:first-child {
  margin-left: 0;
}

@media only screen and (max-width: 1200px) {
  .menu_site_li.active {
    margin-bottom: 40px;
  }
}

.menu_site_li.active .menu_site_a {
  color: #5736AA;
  position: relative;
}

.menu_site_li.active .menu_site_a:after {
  content: '';
  position: absolute;
  width: 5px;
  height: 5px;
  background: #5736AA;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: 100%;
  margin-top: 9px;
  border-radius: 100%;
}

@media only screen and (max-width: 1200px) {
  .menu_site_li {
    margin-left: 0;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 1201px) {
  .menu_site_li:hover ul {
    opacity: 1;
    visibility: visible;
  }
}

.menu_site_li ul {
  position: absolute;
  left: 0;
  top: 100%;
  background: #fff;
  padding: 0 20px;
  padding-top: 23px;
  opacity: 0;
  visibility: hidden;
  transition: all .5s;
  z-index: 9999;
}

@media only screen and (max-width: 750px) {
  .menu_site_li ul {
    display: none;
  }
}

.menu_site_li ul a {
  color: #282828;
  font-size: 15px;
  line-height: 18px;
  transition: all .4s;
}

@media only screen and (min-width: 1201px) {
  .menu_site_li ul a:hover {
    color: #5736AA;
  }
}

.menu_site_li ul li {
  margin-bottom: 18px;
  white-space: nowrap;
}

.menu_site_a {
  color: #282828;
  font-size: 15px;
  font-family: "Roboto Medium", Helvetica, Arial, sans-serif;
}

.menu_contact {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .menu_contact {
    display: block;
    opacity: 0;
    transition: all .5s;
  }
}

.menu_contact_a {
  display: block;
  color: rgba(40, 40, 40, 0.3);
}

.menu_contact_text {
  color: rgba(40, 40, 40, 0.3);
  font-size: 13px;
  line-height: 24px;
  margin-left: 60px;
}

.menu_contact_text:first-child {
  margin-left: 0;
}

@media only screen and (max-width: 1200px) {
  .menu_contact_text {
    margin-left: 0;
    margin-bottom: 24px;
  }
}

.menu_contact_white {
  font-family: "Roboto Bold", Helvetica, Arial, sans-serif;
  font-size: 15px;
  color: #000;
  display: block;
  line-height: 40px;
}

.menu_contact_bottom {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  margin-top: 10px;
}

@media only screen and (max-width: 1200px) {
  .menu_contact_bottom {
    flex-direction: column-reverse;
    padding-bottom: 100px;
    height: auto;
    margin-top: 0;
  }
}

@media only screen and (max-width: 750px) {
  .menu_contact_bottom {
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 1200px) {
  .menu_contact .flex_between {
    display: block;
    text-align: center;
  }
  .menu_contact_contact {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 1200px) {
  .menu_contact .facility {
    margin-bottom: 24px;
  }
}

.menu_contact .ico {
  border-color: #282828;
  margin: auto;
}

.menu_contact .ico_fb:before {
  background-image: url("../img/svg-sprite/sprite.svg");
  background-position: -4px -2300px;
  width: 7px;
  height: 16px;
  background-repeat: no-repeat;
}

.structure {
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 126px;
}

@media only screen and (max-width: 1200px) {
  .structure {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.structure_item {
  margin-bottom: 60px;
  width: 460px;
  height: 155px;
  margin-right: 60px;
  background: rgba(240, 237, 246, 0.4);
  box-shadow: 0 0px 0px 0 rgba(113, 69, 203, 0);
  border-radius: 4px;
  display: flex;
  align-items: baseline;
  font-family: "Montserrat SemiBold", Helvetica, Arial, sans-serif;
  font-size: 21px;
  line-height: 32px;
  color: #282828;
  padding-top: 45px;
  padding-left: 100px;
  transition: all .5s;
  position: relative;
}

.structure_item:nth-child(even) {
  margin-right: 0;
}

.structure_item span {
  font-size: 15px;
  color: #6C6C6C;
  font-family: "Roboto Regular", Helvetica, Arial, sans-serif;
  line-height: 24px;
  margin-right: 15px;
  opacity: 0.5;
  position: relative;
}

@media only screen and (min-width: 1201px) {
  .structure_item:hover {
    background: #FFFFFF;
    box-shadow: 0 14px 32px 0 rgba(113, 69, 203, 0.11);
  }
  .structure_item:hover .btn_right {
    opacity: 1;
  }
  .structure_item:hover .structure_circle i {
    background: #D1C5EB;
  }
  .structure_item:hover .structure_circle:before {
    border: none;
    background: #E2DBF3;
  }
  .structure_item:hover .structure_circle:after {
    border: none;
    background: #F6F3FB;
  }
}

@media only screen and (max-width: 1200px) {
  .structure_item {
    background: #FFFFFF;
    box-shadow: 0 14px 32px 0 rgba(113, 69, 203, 0.11);
    margin-right: 0;
    max-width: 100%;
  }
  .structure_item .structure_circle i {
    background: #D1C6EB;
  }
  .structure_item .structure_circle:before {
    border: none;
    background: rgba(209, 198, 235, 0.54);
  }
  .structure_item .structure_circle:after {
    border: none;
    background: rgba(209, 198, 235, 0.2);
  }
}

@media only screen and (max-width: 750px) {
  .structure_item {
    height: auto;
    min-height: 155px;
    padding-bottom: 30px;
  }
}

.structure .btn_right {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  left: 100%;
  opacity: 0;
  transition: all .5s;
}

@media only screen and (max-width: 1200px) {
  .structure .btn_right {
    opacity: 1;
    left: 50%;
    top: 100%;
  }
}

.structure_circle {
  position: absolute;
  left: -40px;
}

.structure_circle i {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 8px;
  height: 8px;
  z-index: 3;
  border-radius: 100%;
  background: #E0E0E0;
  transition: all .3s;
}

.structure_circle:before {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  content: '';
  width: 18px;
  height: 18px;
  z-index: 2;
  border: 1px #E0E0E0 solid;
  border-radius: 100%;
  transition: all .3s;
}

.structure_circle:after {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  content: '';
  width: 30px;
  height: 30px;
  z-index: 1;
  border: 1px #E0E0E0 solid;
  border-radius: 100%;
  transition: all .3s;
}

.tab {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

@media only screen and (max-width: 750px) {
  .tab {
    display: block;
  }
}

.tab_link {
  display: block;
  font-size: 15px;
  color: #6C6C6C;
}

.tab_link span {
  font-size: 15px;
  color: #6C6C6C;
  opacity: 0.2;
  font-family: "Roboto Regular", Helvetica, Arial, sans-serif;
}

.tab_link.active {
  color: #282828;
  font-size: 21px;
  font-family: "Montserrat SemiBold", Helvetica, Arial, sans-serif;
}

.tab_link.active span {
  opacity: 0.5;
}

@media only screen and (max-width: 750px) {
  .tab_link {
    margin-bottom: 30px;
    white-space: nowrap;
  }
}

.commision {
  margin-top: 100px;
}

@media only screen and (max-width: 1200px) {
  .commision {
    margin-top: 50px;
  }
}

.c-table {
  display: table;
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .c-table {
    display: block;
    width: 100%;
    text-align: center;
  }
}

.c-table_tr {
  display: table-row;
}

.c-table_tr:last-child .c-table_td {
  border-bottom: none;
}

@media only screen and (max-width: 1200px) {
  .c-table_tr {
    display: block;
    width: 100%;
    text-align: center;
    border-bottom: #EEECF2 1px solid;
  }
}

.c-table_td {
  display: table-cell;
  line-height: 24px;
  border-bottom: #EEECF2 1px solid;
  padding-top: 60px;
  padding-bottom: 67px;
  font-size: 15px;
  color: #6C6C6C;
}

@media only screen and (max-width: 1200px) {
  .c-table_td {
    display: block;
    width: 100%;
    text-align: center;
    height: auto;
    border-bottom: none;
    padding: 0;
  }
}

.c-table_contact {
  line-height: 24px;
  font-size: 15px;
  color: #6C6C6C;
}

.c-table_contact a {
  color: #6C6C6C;
  display: block;
}

@media only screen and (max-width: 1200px) {
  .c-table_contact {
    padding-bottom: 50px;
  }
}

.c-table_tel {
  margin-bottom: 24px;
  color: #282828;
  font-family: "Roboto Medium", Helvetica, Arial, sans-serif;
  display: flex;
}

@media only screen and (max-width: 1200px) {
  .c-table_tel {
    display: block;
    width: 100%;
    text-align: center;
  }
}

.c-table_tel a {
  color: #282828;
  margin-right: 20px;
  white-space: nowrap;
}

@media only screen and (max-width: 1200px) {
  .c-table_tel a {
    margin-right: 0;
  }
}

.c-table_name {
  color: #282828;
  font-size: 15px;
  font-family: "Roboto Medium", Helvetica, Arial, sans-serif;
  width: 200px;
}

@media only screen and (max-width: 1200px) {
  .c-table_name {
    display: block;
    width: 100%;
    text-align: center;
    padding-top: 53px;
    padding-bottom: 24px;
  }
}

.c-table_text {
  padding-left: 35px;
  position: relative;
  width: 400px;
  padding-right: 70px;
}

.c-table_text:before {
  content: '';
  position: absolute;
  top: 3px;
  left: 0;
  background-image: url("../img/svg-sprite/sprite.svg");
  background-position: -4px -2206px;
  width: 17px;
  height: 16px;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 1200px) {
  .c-table_text {
    display: block;
    width: 100%;
    text-align: center;
    padding-bottom: 24px;
    padding-right: 35px;
  }
}

.heads {
  margin-top: 100px;
}

.heads_item {
  margin-bottom: 20px;
  position: relative;
}

@media only screen and (max-width: 1200px) {
  .heads_item {
    margin-bottom: 100px;
  }
}

.heads_flex {
  display: flex;
  align-items: center;
  height: 236px;
  background: #FFFFFF;
  box-shadow: 0 6px 15px 0 rgba(113, 69, 203, 0.11);
}

@media only screen and (max-width: 1200px) {
  .heads_flex {
    display: block;
    height: auto;
    text-align: center;
  }
}

.heads_image {
  width: 146px;
  height: 200px;
  background: #EBE8F3;
  box-shadow: 0 8px 15px 0 rgba(113, 69, 203, 0.11);
  border-radius: 40%;
  padding: 10px;
  margin-left: 18px;
  margin-right: 36px;
}

@media only screen and (max-width: 1200px) {
  .heads_image {
    margin: auto;
    margin-bottom: 24px;
  }
}

.heads_img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  box-shadow: 0 8px 15px 0 rgba(88, 75, 117, 0.26);
  border-radius: 40%;
  border: 5px #D5D2DC solid;
}

.heads_text {
  line-height: 24px;
  font-size: 15px;
  color: #6C6C6C;
  padding-left: 35px;
  position: relative;
  min-width: 400px;
  width: 400px;
}

.heads_text:before {
  content: '';
  position: absolute;
  top: 3px;
  left: 0;
  background-image: url("../img/svg-sprite/sprite.svg");
  background-position: -4px -2226px;
  width: 17px;
  height: 16px;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 1200px) {
  .heads_text:before {
    left: 10px;
  }
}

@media only screen and (max-width: 1200px) {
  .heads_text {
    display: block;
    width: 100%;
    min-width: 0;
    text-align: center;
    padding-bottom: 24px;
    padding-right: 35px;
  }
}

.heads_contact {
  line-height: 24px;
  font-size: 15px;
  color: #6C6C6C;
}

@media only screen and (max-width: 1200px) {
  .heads_contact {
    padding-bottom: 50px;
  }
}

.heads_contact a {
  color: #6C6C6C;
  display: block;
}

.heads_tel {
  margin-bottom: 24px;
  color: #282828;
  font-family: "Roboto Medium", Helvetica, Arial, sans-serif;
  display: flex;
}

@media only screen and (max-width: 1200px) {
  .heads_tel {
    display: block;
    width: 100%;
    text-align: center;
  }
}

.heads_tel a {
  color: #282828;
  margin-right: 20px;
}

@media only screen and (max-width: 1200px) {
  .heads_tel a {
    margin-right: 0;
  }
}

.heads_title {
  color: #282828;
  font-size: 18px;
  margin-bottom: 24px;
  font-family: "Roboto Medium", Helvetica, Arial, sans-serif;
}

.heads_content {
  background: url("../img/general/airbg.png") right bottom no-repeat;
  background-color: #F0EDF6;
  display: none;
}

.heads_content li {
  font-size: 15px;
  line-height: 24px;
  color: #6C6C6C;
  margin-bottom: 24px;
  position: relative;
  padding-left: 26px;
}

.heads_content li:before {
  content: '';
  width: 8px;
  height: 8px;
  background: #D1C6EB;
  position: absolute;
  left: 0;
  top: 8px;
  border-radius: 100%;
}

.heads_content li strong {
  font-family: "Roboto Medium", Helvetica, Arial, sans-serif;
  color: #000;
}

.heads_p {
  padding: 75px 160px 65px 210px;
}

@media only screen and (max-width: 1200px) {
  .heads_p {
    padding: 50px 10px;
  }
}

.heads .btn_plus {
  position: absolute;
  right: -22px;
  bottom: 95px;
}

@media only screen and (max-width: 1200px) {
  .heads .btn_plus {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    top: 100%;
  }
}

.list {
  margin-top: 70px;
}

.list .title_min {
  text-transform: uppercase;
  font-family: "Roboto Bold", Helvetica, Arial, sans-serif;
}

.list_wrap {
  margin-top: 20px;
  margin-bottom: 70px;
}

.list_min {
  border-bottom: 1px #EEECF2 solid;
  height: 71px;
  display: flex;
  align-items: center;
  line-height: 24px;
}

.list_min:last-child {
  border-bottom: none;
}

.list_big {
  position: relative;
  background: #FFFFFF;
  box-shadow: 0 6px 15px 0 rgba(113, 69, 203, 0.11);
  margin-top: 20px;
}

.list_title {
  height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #282828;
  font-size: 21px;
  font-family: "Roboto Bold", Helvetica, Arial, sans-serif;
  line-height: 36px;
  text-align: center;
}

.list .btn_plus {
  position: absolute;
  right: -22px;
  bottom: 95px;
}

@media only screen and (max-width: 1200px) {
  .list .btn_plus {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    top: 100%;
  }
}

.list_content {
  background-color: #F0EDF6;
  display: none;
}

.list_content h1, .list_content h2, .list_content h3 {
  color: #282828;
  font-size: 15px;
  text-transform: uppercase;
  font-family: "Roboto Regular", Helvetica, Arial, sans-serif;
  text-align: center;
  margin-bottom: 24px;
  line-height: 48px;
}

.list_content p {
  margin-bottom: 24px;
  color: #6C6C6C;
}

.list_content li {
  font-size: 15px;
  line-height: 24px;
  color: #6C6C6C;
  margin-bottom: 48px;
  position: relative;
  padding-left: 26px;
}

.list_content li:before {
  content: '';
  width: 5px;
  height: 5px;
  background: #5736AA;
  position: absolute;
  left: 0;
  top: 8px;
  border-radius: 100%;
}

.list_content li strong {
  font-family: "Roboto Medium", Helvetica, Arial, sans-serif;
  color: #000;
  margin-bottom: 24px;
}

.list_p {
  padding: 75px 160px 65px 160px;
}

@media only screen and (max-width: 1200px) {
  .list_p {
    padding: 50px 10px;
  }
}

.content {
  position: relative;
}

.home_top {
  position: relative;
}

.home_top .btn {
  margin-top: 47px;
}

@media only screen and (max-width: 1200px) {
  .home_top .btn {
    margin-left: auto;
    margin-right: auto;
  }
}

.home_top:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 25%;
  transform: translate(-50%, 30%);
  background: #FFFFFF;
  box-shadow: -31px 10px 42px 1px rgba(113, 69, 203, 0.11);
  width: 70vw;
  height: 70vw;
  border-radius: 100%;
  z-index: -1;
}

@media only screen and (max-width: 1200px) {
  .home_top:before {
    width: 70vh;
    height: 70vh;
  }
}

@media only screen and (max-width: 1200px) and (orientation: landscape) {
  .home_top:before {
    width: 70vw;
    height: 70vw;
  }
}

@media only screen and (max-width: 1200px) {
  .home_top {
    text-align: center;
  }
}

.home-slider {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

@media only screen and (max-width: 1200px) {
  .home-slider {
    flex-direction: column-reverse;
  }
  .home-slider br {
    display: none;
  }
}

.home-slider_control {
  margin-right: 20px;
}

@media only screen and (max-width: 1200px) {
  .home-slider_control {
    margin: auto;
    display: flex;
    position: relative;
    width: 100%;
    justify-content: center;
  }
}

.home-slider_btn {
  position: relative;
  cursor: pointer;
  margin-top: -10px;
}

.home-slider_btn:before {
  content: '';
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 3px;
  height: 3px;
  background: #DBD2F1;
  border-radius: 100%;
}

.home-slider_btn.active .home-slider_content {
  opacity: 1;
  visibility: visible;
}

.home-slider_btn.active .home-slider_line:before {
  width: 100%;
}

.home-slider_btn.active .home-slider_index {
  transform: scale(1);
}

.home-slider_btn.active .home-slider_index svg {
  transition: opacity .1s, stroke-dashoffset 5s .5s;
  opacity: 1;
  stroke-dashoffset: -251px;
}

.home-slider_btn.active .home-slider_title {
  transform: none;
  transition: all .7s;
  opacity: 1;
}

.home-slider_btn.active .home-slider_text {
  transform: none;
  transition: all .7s .2s;
  opacity: 0.5;
}

@media only screen and (max-width: 1200px) {
  .home-slider_btn {
    margin: 0;
  }
}

.home-slider_index {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5736AA;
  cursor: pointer;
  font-size: 14px;
  font-family: "Montserrat SemiBold", Helvetica, Arial, sans-serif;
  border: 1px #DBD2F1 solid;
  border-radius: 100%;
  transform: scale(0);
  background: #fff;
  transition: all .5s;
}

.home-slider_index svg {
  position: absolute;
  top: -2px;
  left: -2px;
  width: 44px;
  height: 44px;
  stroke-dasharray: 126px;
  stroke-dashoffset: -126px;
  transform: rotate(-90deg);
  transition: opacity .3s, stroke-dashoffset 0s .3s;
  opacity: 0;
}

.home-slider_next {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 100%;
  background: #EAE7F1;
  margin-top: 10px;
}

.home-slider_next:before {
  content: '';
  background-image: url("../img/svg-sprite/sprite.svg");
  background-position: -4px -2185px;
  width: 15px;
  height: 17px;
  background-repeat: no-repeat;
  opacity: 0.1;
  transition: all .5s;
}

@media only screen and (min-width: 1201px) {
  .home-slider_next:hover:before {
    opacity: .5;
  }
}

@media only screen and (max-width: 1200px) {
  .home-slider_next {
    margin: 0;
    margin-left: 10px;
  }
  .home-slider_next:before {
    background-image: url("../img/svg-sprite/sprite.svg");
    background-position: -4px -2226px;
    width: 17px;
    height: 16px;
    background-repeat: no-repeat;
  }
}

.home-slider_content {
  position: absolute;
  top: 50%;
  right: 100%;
  padding-right: 160px;
  margin-right: 20px;
  visibility: hidden;
  opacity: 0;
  transition: all .5s;
}

@media only screen and (max-width: 1200px) {
  .home-slider_content {
    top: 0;
    left: 0;
    width: 100%;
    display: none;
  }
}

.home-slider_content_mob {
  display: none;
}

.home-slider_content_mob_wrap {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .home-slider_content_mob_wrap {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 1200px) {
  .home-slider_content_mob {
    transition: all .3s;
  }
  .home-slider_content_mob .home-slider_title {
    width: 100%;
    text-align: center;
  }
  .home-slider_content_mob .home-slider_text {
    width: 100%;
    text-align: center;
  }
  .home-slider_content_mob.active {
    display: block;
    opacity: 0;
  }
  .home-slider_content_mob.active.show {
    opacity: 1;
  }
  .home-slider_content_mob.active .home-slider_content {
    opacity: 1;
    visibility: visible;
  }
  .home-slider_content_mob.active .home-slider_line:before {
    width: 100%;
  }
  .home-slider_content_mob.active .home-slider_index {
    transform: scale(1);
  }
  .home-slider_content_mob.active .home-slider_index svg {
    transition: opacity .1s, stroke-dashoffset 5s .5s;
    opacity: 1;
    stroke-dashoffset: -251px;
  }
  .home-slider_content_mob.active .home-slider_title {
    transform: none;
    transition: all .7s;
    opacity: 1;
  }
  .home-slider_content_mob.active .home-slider_text {
    transform: none;
    transition: all .7s .2s;
    opacity: 0.5;
  }
}

.home-slider_title {
  color: #6C6C6C;
  font-size: 15px;
  line-height: 24px;
  margin-top: -12px;
  width: 170px;
  transform: translateY(20px);
  transition: all 0s .5s;
  margin-bottom: 25px;
  opacity: 0;
}

.home-slider_text {
  color: #6C6C6C;
  font-size: 15px;
  line-height: 24px;
  opacity: 0;
  transform: translateY(20px);
  transition: all .5s .5s;
}

.home-slider_line {
  width: 150px;
  height: 2px;
  position: absolute;
  top: 0;
  right: 0;
}

.home-slider_line:before {
  content: '';
  width: 0;
  height: 2px;
  position: absolute;
  top: 0;
  right: 0;
  background: #E0E0E0;
  transition: all .5s;
}

@media only screen and (max-width: 1200px) {
  .home-slider_line {
    display: none;
  }
}

.home-slider_anim {
  width: 458px;
  position: relative;
  margin: 0px -20px 0px 0;
}

.home-slider_anim_content {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: 35px;
  width: 378px;
  height: 520px;
  overflow: hidden;
  border-radius: 41%;
}

@media only screen and (max-width: 750px) {
  .home-slider_anim_content {
    top: 15px;
    width: 250px;
    height: 346px;
  }
}

.home-slider_anim_content svg {
  width: 378px;
  position: relative;
  display: block;
  z-index: 999;
}

@media only screen and (max-width: 750px) {
  .home-slider_anim_content svg {
    width: 250px;
  }
}

.home-slider_anim.hide .home-slider_shade {
  transform: none;
}

@media only screen and (max-width: 1200px) {
  .home-slider_anim {
    margin: auto;
  }
}

@media only screen and (max-width: 750px) {
  .home-slider_anim {
    width: 300px;
  }
}

.home-slider_slide {
  width: 100%;
  height: 100%;
  border-radius: 42%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 2px;
}

.home-slider_img {
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: 42%;
}

.home-slider_img:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../img/content/home/slider/cloud.png") center;
  background-size: cover;
  animation: cloud 20s linear infinite;
}

@media only screen and (max-width: 1200px) {
  .home-slider_img:after {
    animation: none;
  }
}

.home-slider_img.hide {
  display: none;
}

.home-slider_img.active {
  display: block;
}

.home-slider_shade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 2;
  transform: translateY(-80%);
  background: url("../img/content/home/slider/hide.jpg") bottom center;
  background-size: cover;
  transition: all .3s ease-out;
}

.home-slider_plane {
  opacity: 0;
  visibility: hidden;
  display: block;
  position: absolute;
  left: 60px;
  width: 0;
}

.home-slider_plane.active {
  opacity: 1;
  visibility: visible;
  width: 100%;
  transition: all 1s ease-in;
}

@media only screen and (max-width: 750px) {
  .home-slider_plane.active {
    width: 80%;
  }
}

.home-slider_plane.out {
  width: 150%;
  opacity: 0;
  left: 100%;
  transition: all .5s ease-out;
}

.home-slider_plane_wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
}

.over-hide {
  width: 100%;
  overflow: hidden;
  margin: -50px 0px -50px;
}

@media only screen and (max-width: 1200px) {
  .over-hide {
    margin: 50px auto;
  }
}

@keyframes cloud {
  0% {
    background-position: 0;
  }
  100% {
    background-position: -300%;
  }
}

@media only screen and (max-width: 1200px) {
  .home-about {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}

.home-about_text {
  column-count: 2;
  column-gap: 70px;
  font-size: 15px;
  color: #6C6C6C;
  margin: 56px 0 49px;
}

@media only screen and (max-width: 1200px) {
  .home-about_text {
    column-count: 1;
  }
  .home-about_text br {
    display: none;
  }
}

.member {
  padding-top: 150px;
  padding-bottom: 150px;
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 1200px) and (max-width: 1200px) and (min-width: 750px) {
  .member .wrapper {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    padding: 0;
  }
}

@media only screen and (max-width: 750px) {
  .member {
    padding-top: 110px;
    padding-bottom: 110px;
  }
}

.member_text {
  max-width: 320px;
  font-size: 15px;
  color: #6C6C6C;
  margin: 58px 0 60px;
}

.member_form, .member_done {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 50%;
  background: #FFFFFF;
  border-radius: 6px;
  padding-left: 130px;
}

.member_form svg, .member_done svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

@media only screen and (max-width: 1200px) {
  .member_form, .member_done {
    position: relative;
    transform: translate(0, 0);
    top: auto;
    left: auto;
    padding-top: 1px;
    width: 710px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 750px) {
  .member_form, .member_done {
    padding: 0;
    width: 300px;
    padding-top: 100px;
    margin-top: 50px;
  }
}

.member_flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
  margin-top: 38px;
}

@media only screen and (max-width: 750px) {
  .member_flex {
    display: block;
    margin-bottom: 0;
    margin-top: 0;
  }
}

.member_wrap {
  width: 460px;
  position: relative;
  padding-bottom: 20px;
}

.member_wrap .btn {
  position: absolute;
  bottom: 0;
  margin-bottom: -22px;
  left: 0;
}

@media only screen and (max-width: 750px) {
  .member_wrap .btn {
    margin-bottom: -48px;
    left: 0;
    width: 100%;
  }
}

@media only screen and (max-width: 750px) {
  .member_wrap {
    width: 220px;
    margin: auto;
  }
}

.member_wrap:after {
  content: '';
  width: 190px;
  height: 190px;
  background: url("../img/general/form.png") top right no-repeat;
  background-size: contain;
  opacity: 0;
  transition: all 0.5s;
  pointer-events: none;
  position: absolute;
  top: 95px;
  right: -30px;
}

.member .form-group {
  display: block;
  position: relative;
}

.member .form-group.has-error .help-block {
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-width: 750px) {
  .member .form-group {
    margin-bottom: 25px;
  }
}

.member .control-label {
  display: block;
  font-family: "Montserrat SemiBold", Helvetica, Arial, sans-serif;
  color: #6C6C6C;
  font-size: 15px;
  margin-bottom: 9px;
}

.member .help-block {
  position: absolute;
  right: 0;
  top: 100%;
  font-size: 12px;
  color: #E94E71;
  margin-top: 4px;
  opacity: 0;
  visibility: hidden;
  transition: all .5s;
}

.member .form-control {
  display: block;
  width: 220px;
  height: 43px;
  background: rgba(237, 236, 241, 0.4);
  font-size: 15px;
  color: #282828;
  padding-left: 20px;
  border-radius: 6px;
}

.member .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #C0C0C0;
}

.member .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #C0C0C0;
}

.member .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #C0C0C0;
}

.member .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #C0C0C0;
}

.member_status {
  color: #DDD9E6;
  font-size: 15px;
  position: absolute;
  left: 0;
  top: 0;
  width: 55px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.member_status span {
  transform: rotate(-90deg);
  white-space: nowrap;
}

.member_crop {
  position: absolute;
  left: 0;
  transform: translate(0, -50%);
  width: 26px;
  height: 26px;
  border-radius: 100%;
  background: #fff;
  box-shadow: 0 3px 15px 0 rgba(113, 69, 203, 0.11) inset;
}

.member_crop:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  background: #fff;
}

.member_crop.top {
  top: 0;
}

.member_crop.top:after {
  top: 0;
}

.member_crop.bottom {
  bottom: 0;
  transform: translate(0, 50%);
}

.member_crop.bottom:after {
  bottom: 0;
}

.member_crop_wrap {
  position: absolute;
  height: 100%;
  right: -13px;
  top: 0;
  width: 26px;
  overflow: hidden;
}

@media only screen and (max-width: 750px) {
  .member_tablet {
    display: none !important;
  }
}

.member_mobile {
  display: none;
}

@media only screen and (max-width: 750px) {
  .member_mobile {
    display: block;
  }
}

.transfers {
  margin-bottom: 70px;
}

.transfers_wrap {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-top: 58px;
}

@media only screen and (max-width: 750px) {
  .transfers {
    margin-bottom: 20px;
  }
}

.transfers_item {
  width: 247px;
  cursor: pointer;
}

@media only screen and (min-width: 1201px) {
  .transfers_item:hover .transfers_shade {
    top: -50%;
  }
}

.transfers_item.active .transfers_shade {
  top: -90%;
}

.transfers_image {
  width: 81px;
  height: 115px;
  margin: auto;
  margin-bottom: 31px;
  box-shadow: 0 25px 18px 0 rgba(87, 54, 170, 0.12);
  border-radius: 30%;
  position: relative;
  overflow: hidden;
  background-clip: content-box;
}

.transfers_image:before {
  content: '';
  position: absolute;
  top: 1px;
  left: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border-radius: 30%;
  background-image: linear-gradient(35deg, #3DBDFF 0%, #8D5AFF 100%);
}

@media only screen and (max-width: 750px) {
  .transfers_image {
    width: 60px;
    height: 90px;
  }
}

.transfers_ico {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.transfers_shade {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 2;
  top: -10%;
  background: url("../img/content/home/slider/hide.jpg") bottom center;
  background-size: cover;
  transition: all .3s ease-out;
}

.transfers_shade span {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  color: #DDD9E6;
  font-size: 24px;
  font-family: "Roboto Medium", Helvetica, Arial, sans-serif;
}

.transfers_title {
  color: #282828;
  font-family: "Roboto Medium", Helvetica, Arial, sans-serif;
  line-height: 27px;
  display: flex;
  margin-right: 80px;
  min-width: 200px;
}

.transfers_title span {
  font-family: "Roboto Bold", Helvetica, Arial, sans-serif;
  color: #DDD9E6;
  font-size: 17px;
  margin-right: 10px;
}

.transfers_text {
  color: #6C6C6C;
  font-size: 15px;
  line-height: 24px;
  width: 100%;
}

.transfers_slide .flex_between {
  display: flex;
  width: 750px;
  margin: auto;
  justify-content: space-between;
  margin-top: 80px;
}

@media only screen and (max-width: 1200px) {
  .transfers_slide .flex_between {
    width: 700px;
  }
  .transfers_slide .flex_between br {
    display: none;
  }
}

@media only screen and (max-width: 750px) {
  .transfers_slide .flex_between {
    display: block;
    margin-top: 30px;
    width: 100%;
  }
}

.partners {
  padding-top: 70px;
  padding-bottom: 170px;
  background: url("../img/content/map.png") top right no-repeat;
  width: 100%;
  overflow: hidden;
}

@media only screen and (max-width: 750px) {
  .partners {
    padding-bottom: 100px;
  }
}

.partners_text {
  margin: 54px 0 40px;
}

.partners_wrap {
  width: 370px;
}

.partners_flex {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  .partners_flex {
    position: relative;
  }
}

@media only screen and (max-width: 750px) {
  .partners_flex {
    flex-direction: column-reverse;
  }
}

.partners_img {
  width: 590px;
  background: #FFFFFF;
  box-shadow: 0 8px 15px 0 rgba(113, 69, 203, 0.06);
  border-radius: 170px;
  padding: 30px;
}

.partners_img img {
  display: block;
  width: 100%;
  border: 10px #fff solid;
  box-shadow: 0 8px 15px 0 rgba(113, 69, 203, 0.06);
  border-radius: 140px;
}

@media only screen and (max-width: 750px) {
  .partners_img {
    width: 320px;
    padding: 10px;
  }
}

.partners_slide {
  width: 266px;
  height: 140px;
  background: #FFFFFF;
  box-shadow: 0 8px 15px 0 rgba(113, 69, 203, 0.11);
  display: flex;
  justify-content: center;
  align-items: center;
}

.partners_slide img {
  display: block;
  max-width: 90%;
  max-height: 90%;
}

.partners .bx-viewport {
  overflow: visible !important;
  margin-left: -133px;
  margin-top: -70px;
}

@media only screen and (max-width: 750px) {
  .partners .bx-viewport {
    margin-left: 30px;
  }
}

.partners_control {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}

@media only screen and (max-width: 1200px) {
  .partners_control {
    position: absolute;
    top: 100%;
    margin-top: 100px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.partners_prev {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5736AA;
  cursor: pointer;
  font-size: 14px;
  font-family: "Montserrat SemiBold", Helvetica, Arial, sans-serif;
  border: 1px #E0E0E0 solid;
  border-radius: 100%;
  background: #fff;
  position: relative;
}

.partners_prev .arrow-right {
  margin: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background-image: url("../img/svg-sprite/sprite.svg");
  background-position: -4px -2246px;
  width: 16px;
  height: 13px;
  background-repeat: no-repeat;
}

.partners_prev .arrow-left {
  margin: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background-image: url("../img/svg-sprite/sprite.svg");
  background-position: -4px -2263px;
  width: 16px;
  height: 13px;
  background-repeat: no-repeat;
}

.partners_next {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5736AA;
  cursor: pointer;
  font-size: 14px;
  font-family: "Montserrat SemiBold", Helvetica, Arial, sans-serif;
  border: 1px #E0E0E0 solid;
  border-radius: 100%;
  margin-left: 12px;
  background: #fff;
  position: relative;
}

.partners_next svg {
  position: absolute;
  top: -2px;
  left: -2px;
  width: 44px;
  height: 44px;
  stroke-dasharray: 126px;
  stroke-dashoffset: -126px;
  transform: rotate(-90deg);
  transition: opacity .3s, stroke-dashoffset 0s .3s;
  opacity: 0;
}

.partners_next.active svg {
  transition: opacity .1s, stroke-dashoffset 5s .5s;
  opacity: 1;
  stroke-dashoffset: -251px;
}

.partners_next .arrow-right {
  margin: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background-image: url("../img/svg-sprite/sprite.svg");
  background-position: -4px -2246px;
  width: 16px;
  height: 13px;
  background-repeat: no-repeat;
}

.home_news {
  margin-bottom: 100px;
  width: 100%;
  overflow: hidden;
  padding-bottom: 50px;
}

@media only screen and (max-width: 750px) {
  .home_news {
    position: relative;
  }
}

.home_news .title {
  margin-top: 50px;
}

@media only screen and (max-width: 750px) {
  .home_news .title br {
    display: none;
  }
}

.home_news_control {
  display: flex;
  align-items: center;
  margin-top: 43px;
  margin-bottom: 115px;
}

@media only screen and (max-width: 750px) {
  .home_news_control {
    justify-content: center;
    position: absolute;
    left: 0;
    top: 500px;
    margin: 0;
    width: 100%;
  }
}

.home_news_left {
  display: inline-block;
}

@media only screen and (max-width: 750px) {
  .home_news_left {
    display: block;
  }
}

.home_news_btn {
  position: relative;
  cursor: pointer;
  margin-left: -10px;
}

.home_news_btn:before {
  content: '';
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 3px;
  height: 3px;
  background: #DBD2F1;
  border-radius: 100%;
}

.home_news_btn.active .home_news_index {
  transform: scale(1);
}

.home_news_btn.active .home_news_index svg {
  transition: opacity .1s, stroke-dashoffset 5s .5s;
  opacity: 1;
  stroke-dashoffset: -251px;
}

.home_news_index {
  width: 42px;
  min-width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5736AA;
  cursor: pointer;
  font-size: 14px;
  font-family: "Montserrat SemiBold", Helvetica, Arial, sans-serif;
  border: 1px #DBD2F1 solid;
  border-radius: 100%;
  transform: scale(0);
  background: #fff;
  transition: all .5s;
}

.home_news_index svg {
  position: absolute;
  top: -2px;
  left: -2px;
  width: 44px;
  height: 44px;
  stroke-dasharray: 126px;
  stroke-dashoffset: -126px;
  transform: rotate(-90deg);
  transition: opacity .3s, stroke-dashoffset 0s .3s;
  opacity: 0;
}

.home_news_next {
  width: 42px;
  min-width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 100%;
  background: #EAE7F1;
  transform: rotate(-90deg);
  margin-left: 10px;
}

.home_news_next:before {
  content: '';
  background-image: url("../img/svg-sprite/sprite.svg");
  background-position: -4px -2185px;
  width: 15px;
  height: 17px;
  background-repeat: no-repeat;
  opacity: 0.1;
  transition: all .5s;
}

@media only screen and (min-width: 1201px) {
  .home_news_next:hover:before {
    opacity: .5;
  }
}

.home_news .btn_text {
  float: right;
}

.home_news .btn_text.mob {
  display: none;
}

@media only screen and (max-width: 750px) {
  .home_news .btn_text {
    display: none;
  }
  .home_news .btn_text.mob {
    display: block;
    float: none;
    margin-top: 100px;
    text-align: center;
  }
}

.home_news_slider {
  font-size: 0;
  white-space: nowrap;
  display: inline-block;
  width: 300px;
  margin-left: 70px;
  vertical-align: top;
  position: relative;
}

@media only screen and (max-width: 750px) {
  .home_news_slider {
    margin-left: 0;
    margin-top: 30px;
  }
}

.home_news_slide {
  font-size: 15px;
  color: #B5B5B5;
  display: inline-block;
  width: 320px;
  background: #FFFFFF;
  box-shadow: 0 14px 32px 0 rgba(113, 69, 203, 0.11);
  border-radius: 4px;
  margin-right: 10px;
  height: 360px;
  vertical-align: top;
  position: relative;
  transition: all .5s;
  white-space: normal;
}

.home_news_slide:nth-child(2) {
  opacity: .75;
}

.home_news_slide:nth-child(3) {
  opacity: .50;
}

.home_news_slide:nth-child(4) {
  opacity: .35;
}

.home_news_slide:nth-child(5) {
  opacity: .20;
}

.home_news_slide:nth-child(6) {
  opacity: .10;
}

.home_news_slide.hide {
  opacity: 0;
  transform: scale(0.2);
}

.home_news_slide.noanim {
  transition: opacity .5s, margin 0s;
}

.home_news_image {
  width: 100%;
  height: 196px;
  background-size: cover;
  display: block;
}

.home_news_title {
  padding: 20px 30px;
  font-family: "Roboto Medium", Helvetica, Arial, sans-serif;
  font-size: 18px;
  color: #282828;
  line-height: 27px;
}

.home_news_time {
  position: absolute;
  left: 30px;
  bottom: 25px;
}

.home_news_view {
  position: absolute;
  right: 30px;
  bottom: 25px;
}

.home_news_view:before {
  content: '';
  display: inline-block;
  background-image: url("../img/svg-sprite/sprite.svg");
  background-position: -4px -2151px;
  width: 19px;
  height: 12px;
  background-repeat: no-repeat;
  margin-right: 5px;
  vertical-align: middle;
}

.about-doc {
  padding-bottom: 150px;
  background: url("../img/general/airbg.jpg") right bottom no-repeat;
}

.about-doc .wrapper {
  width: 850px;
}

@media only screen and (max-width: 1200px) {
  .about-doc .wrapper {
    width: 100%;
    justify-content: space-around;
  }
}

@media only screen and (max-width: 750px) {
  .about-doc .wrapper {
    display: block;
  }
}

.about-doc_title {
  font-size: 21px;
  font-family: "Montserrat SemiBold", Helvetica, Arial, sans-serif;
  line-height: 36px;
  color: #282828;
  margin-bottom: 34px;
}

.about-doc_item {
  padding: 35px 61px 0 44px;
  width: 320px;
  height: 126px;
  font-size: 15px;
  color: #282828;
  line-height: 24px;
  background: #FFFFFF;
  box-shadow: 0 14px 32px 0 rgba(113, 69, 203, 0.11);
  border-radius: 4px;
  margin-bottom: 21px;
  position: relative;
  font-family: "Roboto Medium", Helvetica, Arial, sans-serif;
  margin-right: 100px;
}

@media only screen and (max-width: 1200px) {
  .about-doc_item {
    margin-right: 0;
  }
}

@media only screen and (max-width: 750px) {
  .about-doc_item {
    width: 100%;
    text-align: center;
    margin-bottom: 60px;
  }
}

.about-doc .btn_download {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  left: 100%;
}

@media only screen and (max-width: 750px) {
  .about-doc .btn_download {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    top: 100%;
  }
}

.timeline {
  position: absolute;
  top: 150px;
  left: 50px;
  height: calc(100% - 150px);
  width: 1px;
}

@media only screen and (max-width: 1200px) {
  .timeline {
    display: none;
  }
}

.timeline:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 1px;
  background: url("../img/content/home/line.png");
}

.timeline_pin {
  width: 8px;
  height: 8px;
  background: #D1C6EB;
  border-radius: 100%;
  transform: translateX(-50%);
  position: absolute;
  transition: all .5s;
  left: 0;
}

.timeline_pin.hide {
  opacity: 0;
  transition: all 0s;
}

.timeline_pin.active {
  width: 1px;
  height: 1px;
}

.timeline_air {
  position: absolute;
  top: 0;
  left: 0;
  background: #EEEEEE;
  width: 1px;
}

.timeline_air svg {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateX(-50%);
  transition: all .5s;
}

.timeline_air svg.rotate {
  transform: translateX(-50%) rotate(180deg);
}

.doc {
  text-align: center;
  margin-bottom: 120px;
  margin-top: 120px;
}

.doc_item {
  width: 280px;
  margin-top: 61px;
}

@media only screen and (max-width: 750px) {
  .doc_item {
    width: 100%;
  }
}

.doc_link {
  display: block;
  background: #FFFFFF;
  box-shadow: 0 14px 32px 0 rgba(113, 69, 203, 0.11);
  border-radius: 4px;
  width: 185px;
  margin: auto;
  border: 17px #fff solid;
  border-bottom: 10px #fff solid;
  border-top: 10px #fff solid;
  margin-bottom: 46px;
  transition: all .5s;
}

@media only screen and (min-width: 1201px) {
  .doc_link:hover {
    box-shadow: 0 0px 32px 0 rgba(113, 69, 203, 0.11);
  }
}

.doc_img {
  display: block;
  width: 100%;
}

.doc_reg {
  color: rgba(108, 108, 108, 0.5);
  font-size: 13px;
  line-height: 24px;
  margin-top: 16px;
  padding: 0 20px;
}

@media only screen and (max-width: 750px) {
  .doc .flex_between {
    display: block;
  }
}

.statute {
  margin-top: 180px;
}

@media only screen and (max-width: 750px) {
  .statute {
    margin-top: 70px;
  }
}

.statute_item {
  width: 450px;
  margin-left: 100px;
}

@media only screen and (max-width: 1200px) {
  .statute_item {
    margin-left: 20px;
    width: 360px;
  }
}

.statute_item .title_min {
  margin-bottom: 54px;
}

@media only screen and (max-width: 750px) {
  .statute_item .title_min {
    margin-bottom: 30px;
    margin-top: 50px;
  }
}

.statute_item li {
  font-size: 15px;
  line-height: 24px;
  color: #6C6C6C;
  margin-bottom: 24px;
  position: relative;
  padding-left: 26px;
}

.statute_item li:before {
  content: '';
  width: 8px;
  height: 8px;
  background: #D1C6EB;
  position: absolute;
  left: 0;
  top: 8px;
  border-radius: 100%;
}

.statute_item:first-child {
  width: 360px;
}

@media only screen and (max-width: 750px) {
  .statute_item:first-child {
    width: 100%;
  }
}

.statute_item:first-child .title_min {
  margin-left: -100px;
}

@media only screen and (max-width: 1200px) {
  .statute_item:first-child .title_min {
    margin-left: 0;
  }
}

@media only screen and (max-width: 750px) {
  .statute_item {
    width: 100%;
    margin-left: 0;
  }
}

@media only screen and (max-width: 750px) {
  .statute .flex_between {
    display: block;
  }
}

.about-slider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}

@media only screen and (max-width: 1200px) {
  .about-slider {
    justify-content: space-around;
  }
}

@media only screen and (max-width: 750px) {
  .about-slider {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
}

.about-slider_anim {
  width: 458px;
  position: relative;
  margin: 0px 0px 0px -60px;
}

@media only screen and (max-width: 1200px) {
  .about-slider_anim {
    margin-left: -30px;
  }
}

@media only screen and (max-width: 750px) {
  .about-slider_anim {
    margin: 0;
    width: 305px;
  }
  .about-slider_anim svg {
    width: 255px;
  }
}

.about-slider_anim_content {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: 35px;
  width: 378px;
  height: 520px;
  overflow: hidden;
  border-radius: 41%;
}

@media only screen and (max-width: 750px) {
  .about-slider_anim_content {
    width: 208px;
    height: 290px;
    top: 20px;
  }
}

.about-slider_anim_content svg {
  width: 378px;
  position: relative;
  display: block;
  z-index: 999;
}

@media only screen and (max-width: 750px) {
  .about-slider_anim_content svg {
    width: 208px;
    height: 290px;
  }
}

.about-slider_anim.hide .home-slider_shade {
  transform: none;
}

.about-slider_img {
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: 42%;
  visibility: hidden;
  opacity: 0;
  transition: all .5s;
  position: absolute;
  top: 0;
  left: 0;
}

.about-slider_img.active {
  visibility: visible;
  opacity: 1;
}

.about-slider_slide {
  width: 100%;
  height: 100%;
  border-radius: 42%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 2px;
}

.about-slider_content {
  width: 382px;
}

@media only screen and (max-width: 1200px) {
  .about-slider_content {
    width: 300px;
  }
}

.about-slider_slide-in {
  width: 382px;
}

.about-slider_btn {
  position: relative;
  cursor: pointer;
  margin-left: -10px;
}

.about-slider_btn:before {
  content: '';
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 3px;
  height: 3px;
  background: #DBD2F1;
  border-radius: 100%;
}

.about-slider_btn.active .about-slider_index {
  transform: scale(1);
}

.about-slider_btn.active .about-slider_index svg {
  transition: opacity .1s, stroke-dashoffset 5s .5s;
  opacity: 1;
  stroke-dashoffset: -251px;
}

.about-slider_index {
  width: 42px;
  min-width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5736AA;
  cursor: pointer;
  font-size: 14px;
  font-family: "Montserrat SemiBold", Helvetica, Arial, sans-serif;
  border: 1px #DBD2F1 solid;
  border-radius: 100%;
  transform: scale(0);
  background: #fff;
  transition: all .5s;
}

.about-slider_index svg {
  position: absolute;
  top: -2px;
  left: -2px;
  width: 44px;
  height: 44px;
  stroke-dasharray: 126px;
  stroke-dashoffset: -126px;
  transform: rotate(-90deg);
  transition: opacity .3s, stroke-dashoffset 0s .3s;
  opacity: 0;
}

.about-slider_next {
  width: 42px;
  min-width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 100%;
  background: #EAE7F1;
  transform: rotate(-90deg);
  margin-left: 10px;
}

.about-slider_next:before {
  content: '';
  background-image: url("../img/svg-sprite/sprite.svg");
  background-position: -4px -2185px;
  width: 15px;
  height: 17px;
  background-repeat: no-repeat;
  opacity: 0.1;
  transition: all .5s;
}

@media only screen and (min-width: 1201px) {
  .about-slider_next:hover:before {
    opacity: .5;
  }
}

.about-slider_control {
  display: flex;
  align-items: center;
  margin-top: 50px;
}

@media only screen and (max-width: 750px) {
  .about-slider_control {
    justify-content: center;
  }
}

.about-slider_title {
  color: #282828;
  font-size: 21px;
  line-height: 32px;
  font-family: "Montserrat SemiBold", Helvetica, Arial, sans-serif;
  margin-bottom: 44px;
}

.about-slider_title:before {
  content: '';
  background-image: url("../img/svg-sprite/sprite.svg");
  background-position: -4px -2226px;
  width: 17px;
  height: 16px;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  margin-right: 24px;
}

.about-slider_status {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 600px;
  position: relative;
  margin-left: -100px;
}

@media only screen and (max-width: 1200px) {
  .about-slider_status {
    display: none;
  }
}

.about-slider_line {
  width: 130px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
}

.about-slider_line:before {
  content: '';
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  width: 1200px;
  height: 1200px;
  right: 0;
  border-radius: 100%;
  border: 1px dashed #E0E0E0;
}

.about-slider_pin {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  transform: translate(50%);
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.about-slider_pin:nth-child(1) {
  margin-left: -79px;
}

.about-slider_pin:nth-child(5) {
  margin-left: -79px;
}

.about-slider_pin:nth-child(2) {
  margin-left: -19px;
}

.about-slider_pin:nth-child(4) {
  margin-left: -19px;
}

.about-slider_pin:after {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  content: '';
  width: 8px;
  height: 8px;
  z-index: -1;
  background: none;
  border-radius: 100%;
  transition: all .5s;
}

.about-slider_pin i {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 8px;
  height: 8px;
  z-index: 1;
  border-radius: 100%;
  background: #D1C6EB;
}

.about-slider_pin:before {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  content: '';
  width: 8px;
  height: 8px;
  border: 10px #fff solid;
  z-index: -1;
  background: none;
  border-radius: 100%;
  transition: all .5s;
}

.about-slider_pin.active {
  background: #D1C5EB;
}

.about-slider_pin.active:before {
  width: 30px;
  height: 30px;
  background: #F6F3FB;
  border: 20px #fff solid;
}

.about-slider_pin.active:after {
  width: 18px;
  height: 18px;
  background: #E2DBF3;
}

.participants {
  display: flex;
  margin-top: 90px;
  margin-bottom: 50px;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  .participants {
    flex-direction: column;
    align-items: center;
    position: relative;
  }
}

.participants_text {
  font-size: 15px;
  line-height: 24px;
  color: #6C6C6C;
  margin-top: 24px;
  margin-bottom: 76px;
}

@media only screen and (max-width: 1200px) {
  .participants_text {
    margin-bottom: 520px;
  }
}

@media only screen and (max-width: 750px) {
  .participants_text {
    margin-bottom: 360px;
  }
}

.participants_control {
  display: flex;
  align-items: center;
}

.participants_control .btn_link {
  margin-left: 54px;
}

@media only screen and (max-width: 750px) {
  .participants_control .btn_link {
    margin-top: 30px;
    margin-left: 0;
  }
}

@media only screen and (max-width: 750px) {
  .participants_control {
    flex-direction: column;
    align-items: center;
  }
}

.participants_form {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

@media only screen and (max-width: 750px) {
  .participants_form {
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
}

.participants_form .form-group {
  display: block;
  position: relative;
  margin-right: 39px;
}

.participants_form .form-group.has-error .help-block {
  opacity: 1;
  visibility: visible;
}

.participants_form .form-group:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 750px) {
  .participants_form .form-group {
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.participants_form .control-label {
  display: block;
  font-family: "Montserrat SemiBold", Helvetica, Arial, sans-serif;
  color: #6C6C6C;
  font-size: 15px;
  margin-bottom: 9px;
}

.participants_form .help-block {
  position: absolute;
  right: 0;
  top: 100%;
  font-size: 12px;
  color: #E94E71;
  margin-top: 4px;
  opacity: 0;
  visibility: hidden;
  transition: all .5s;
}

.participants_form .form-control {
  display: block;
  width: 220px;
  height: 43px;
  background: rgba(237, 236, 241, 0.4);
  font-size: 15px;
  color: #282828;
  padding-left: 20px;
  border-radius: 6px;
}

.participants_form .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #C0C0C0;
}

.participants_form .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #C0C0C0;
}

.participants_form .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #C0C0C0;
}

.participants_form .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #C0C0C0;
}

.participants_form .select2-hidden-accessible {
  display: none;
}

.participants_form .select2-selection {
  display: flex;
  align-items: center;
  width: 220px;
  height: 43px;
  background: rgba(237, 236, 241, 0.4);
  font-size: 13px;
  padding-left: 20px;
  border-radius: 6px;
  color: #C0C0C0;
  justify-content: space-between;
}

.participants_form .select2-search {
  display: none;
}

.participants_form .select2-selection__arrow {
  display: block;
  background-image: url("../img/svg-sprite/sprite.svg");
  background-position: -4px -2336px;
  width: 8px;
  height: 6px;
  background-repeat: no-repeat;
  margin-right: 18px;
}

.participants_form .select2_res .select2-container {
  background: #fff;
  box-shadow: 0 14px 32px 0 rgba(113, 69, 203, 0.11);
  border-radius: 6px;
  font-size: 15px;
  color: #282828;
  line-height: 18px;
  padding: 14px 0;
  z-index: 999;
  top: 100% !important;
  left: 0 !important;
  width: 100%;
  max-height: 280px;
  overflow: auto;
}

.participants_form .select2_res .select2-results__option {
  padding: 9px 0;
  padding-left: 22px;
  cursor: pointer;
}

@media only screen and (min-width: 1201px) {
  .participants_form .select2_res .select2-results__option:hover {
    color: #5736AA;
  }
}

.radar {
  width: 480px;
  height: 480px;
  border-radius: 100%;
  box-shadow: 0 8px 15px 0 rgba(113, 69, 203, 0.11);
  background: #5736AA;
  border: 12px #fff solid;
  margin-top: -30px;
  position: relative;
}

@media only screen and (max-width: 1200px) {
  .radar {
    position: absolute;
    top: 100px;
    margin-top: 0;
    left: 50%;
    margin-left: -240px;
  }
}

@media only screen and (max-width: 750px) {
  .radar {
    width: 300px;
    height: 300px;
    left: 50%;
    margin-left: -150px;
    overflow: hidden;
    top: 120px;
  }
}

.radar_h {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background: #9C83DC;
  pointer-events: none;
}

.radar_v {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 1px;
  background: #9C83DC;
  pointer-events: none;
}

.radar_in {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 50%;
  height: 50%;
  border: 1px #9C83DC solid;
  border-radius: 100%;
  pointer-events: none;
}

.radar_in2 {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 75%;
  height: 75%;
  border: 1px #9C83DC solid;
  border-radius: 100%;
  pointer-events: none;
}

.radar:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background: url("../img/content/radar.png") center;
  background-size: cover;
  animation: radar 10s linear infinite;
}

.map-all:before {
  content: '';
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background: url("../img/minified-svg/all.svg") center;
  background-size: cover;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  border-radius: 100%;
}

.map-ua:before {
  content: '';
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background: url("../img/minified-svg/ua.svg") center;
  background-size: cover;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  border-radius: 100%;
}

.map-eu:before {
  content: '';
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background: url("../img/minified-svg/europe.svg") center;
  background-size: cover;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  border-radius: 100%;
}

.map-usa:before {
  content: '';
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background: url("../img/minified-svg/america.svg") center;
  background-size: cover;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  border-radius: 100%;
}

.map-knr:before {
  content: '';
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background: url("../img/minified-svg/asia.svg") center;
  background-size: cover;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  border-radius: 100%;
}

.radar_map {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 100%;
  display: none;
}

.radar_map.active {
  display: block;
}

@keyframes radar {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dot {
  position: absolute;
}

.dot:hover {
  z-index: 40;
}

.dot:hover .dot_content {
  opacity: 1;
  visibility: visible;
}

.dot i {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 8px;
  height: 8px;
  z-index: 3;
  border-radius: 100%;
  background: #F9F6FF;
}

.dot:before {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  content: '';
  width: 18px;
  height: 18px;
  z-index: 2;
  background: #D1C6EB;
  border-radius: 100%;
  opacity: .54;
}

.dot:after {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  content: '';
  width: 30px;
  height: 30px;
  z-index: 1;
  background: #E7DDFF;
  border-radius: 100%;
  opacity: .2;
}

.dot_content {
  position: absolute;
  background: #FFFFFF;
  box-shadow: 0 14px 32px 0 rgba(113, 69, 203, 0.11);
  border-radius: 4px;
  white-space: nowrap;
  padding-top: 20px;
  padding-left: 80px;
  padding-bottom: 15px;
  padding-right: 30px;
  left: -40px;
  top: -40px;
  visibility: hidden;
  opacity: 0;
  transition: all .5s;
}

@media only screen and (max-width: 750px) {
  .dot_content {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    padding: 10px 20px;
    z-index: 2;
  }
}

.dot_title {
  font-size: 21px;
  color: #282828;
  font-family: "Montserrat SemiBold", Helvetica, Arial, sans-serif;
  line-height: 32px;
}

.dot_text {
  color: #6C6C6C;
  font-size: 15px;
  line-height: 32px;
  opacity: 0.5;
}

.table .flex_between {
  align-items: baseline;
}

@media only screen and (max-width: 750px) {
  .table .flex_between {
    display: block;
  }
}

.table_title {
  color: #282828;
  font-size: 21px;
  font-family: "Montserrat SemiBold", Helvetica, Arial, sans-serif;
}

@media only screen and (max-width: 750px) {
  .table_title {
    margin-bottom: 12px;
  }
}

.table_count {
  color: #6C6C6C;
  font-size: 15px;
}

.table_tb {
  display: table;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 90px;
}

.table_tr {
  display: table-row;
  width: 100%;
}

@media only screen and (max-width: 750px) {
  .table_tr {
    display: block;
    border-bottom: 1px #EEECF2 solid;
    position: relative;
    padding-left: 100px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 1201px) {
  .table_tr:hover .table_link {
    opacity: 1;
  }
  .table_tr:hover .table_img {
    opacity: 1;
  }
  .table_tr:hover .table_td {
    color: #000000;
  }
  .table_tr:hover .table_td span {
    color: #000000;
  }
}

.table_td {
  border-bottom: 1px #EEECF2 solid;
  display: table-cell;
  height: 72px;
  vertical-align: middle;
  color: #282828;
  transition: all .5s;
  font-size: 15px;
  line-height: 24px;
  padding: 0 10px;
}

.table_td span {
  color: #B5B5B5;
  transition: all .5s;
}

@media only screen and (max-width: 750px) {
  .table_td {
    display: block;
    border-bottom: none;
    height: auto;
  }
}

.table_img {
  opacity: 0.5;
  display: block;
  margin: auto;
  max-height: 50px;
  transition: all .5s;
  max-width: 150px;
}

@media only screen and (max-width: 750px) {
  .table_img {
    position: absolute;
    left: 0;
    top: 30px;
    max-width: 90px;
  }
}

.table_link {
  background-image: url("../img/svg-sprite/sprite.svg");
  background-position: -4px -2246px;
  width: 16px;
  height: 13px;
  background-repeat: no-repeat;
  display: block;
  opacity: 0.5;
  transition: all .5s;
}

@media only screen and (max-width: 750px) {
  .table_link {
    position: absolute;
    right: 0;
    bottom: 35px;
  }
}

.stop {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.stop_title {
  font-family: "Montserrat SemiBold", Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 32px;
  color: #282828;
}

.stop_item {
  display: flex;
  align-items: center;
  margin-top: 50px;
}

.stop_image {
  width: 120px;
  text-align: center;
  font-size: 15px;
  color: #E0E0E0;
  font-family: "Roboto Bold", Helvetica, Arial, sans-serif;
}

@media only screen and (max-width: 750px) {
  .stop_image {
    width: 90px;
    min-width: 90px;
  }
  .stop_image img {
    width: 90px;
  }
}

.stop_img {
  display: block;
  margin: auto;
}

.stop_text {
  color: #9B9B9B;
  font-size: 15px;
  line-height: 24px;
  margin-left: 20px;
  width: 300px;
  min-width: 300px;
}

@media only screen and (max-width: 750px) {
  .stop_text {
    width: auto;
    min-width: 50px;
  }
}

.first-news {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 750px) {
  .first-news {
    flex-direction: column-reverse;
    margin-top: 30px;
  }
  .first-news .home_top {
    display: none;
  }
}

.first-news_text {
  margin: 50px 0;
}

@media only screen and (max-width: 1200px) {
  .first-news_text {
    margin: 25px 0;
  }
}

.first-news_content {
  margin-right: 70px;
}

@media only screen and (max-width: 1200px) {
  .first-news_content {
    margin-right: 30px;
    padding: 50px 0;
  }
}

@media only screen and (max-width: 750px) {
  .first-news_content {
    margin-right: 0;
  }
}

.first-news_image {
  margin-right: -120px;
}

@media only screen and (max-width: 1200px) {
  .first-news_image {
    margin-right: 0;
  }
}

@media only screen and (max-width: 750px) {
  .first-news_image {
    margin-right: 0;
  }
}

.horizontal {
  position: relative;
  display: inline-block;
}

.horizontal svg {
  display: block;
  width: 617px;
}

@media only screen and (max-width: 1200px) {
  .horizontal svg {
    width: 308px;
  }
}

.horizontal_wrap {
  position: absolute;
  z-index: 1;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: 30px;
  border-radius: 42%;
  overflow: hidden;
}

@media only screen and (max-width: 1200px) {
  .horizontal_wrap {
    top: 15px;
  }
}

.horizontal_wrap svg {
  z-index: 1;
  width: 527px;
  position: relative;
}

@media only screen and (max-width: 1200px) {
  .horizontal_wrap svg {
    width: 263px;
  }
}

.horizontal_img {
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background-size: cover;
  border-radius: 42%;
  position: absolute;
  top: 2px;
  left: 2px;
}

.news-filter {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1200px) {
  .news-filter {
    display: block;
    margin-bottom: 42px;
  }
}

.news-sort {
  position: relative;
  display: flex;
  font-size: 15px;
}

.news-sort_list {
  position: absolute;
  top: 100%;
  z-index: 2;
  background: #FFFFFF;
  box-shadow: 0 14px 32px 0 rgba(113, 69, 203, 0.11);
  padding: 30px 0 20px;
  right: 0;
  width: auto;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all .2s;
}

@media only screen and (max-width: 1200px) {
  .news-sort_list {
    left: 0;
    width: 100%;
  }
}

.news-sort_list.active {
  visibility: visible;
  opacity: 1;
}

.news-sort_val {
  color: #9B9B9B;
  display: flex;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
}

.news-sort_val:after {
  content: '';
  display: block;
  background: rgba(87, 54, 170, 0.3);
  width: 5px;
  height: 5px;
  border-radius: 100%;
  margin-left: 10px;
}

.news-sort_link {
  font-size: 15px;
  color: #282828;
  display: block;
  line-height: 34px;
  padding: 0 35px;
}

@media only screen and (min-width: 1201px) {
  .news-sort_link:hover {
    color: #5736AA;
  }
}

.news-tab {
  line-height: 70px;
  font-size: 21px;
}

@media only screen and (max-width: 750px) {
  .news-tab {
    margin-bottom: 30px;
  }
}

.news-tab_link {
  color: #282828;
  opacity: .3;
  margin-right: 60px;
  font-family: "Montserrat SemiBold", Helvetica, Arial, sans-serif;
}

@media only screen and (max-width: 750px) {
  .news-tab_link {
    display: block;
    line-height: 50px;
  }
}

.news-tab_link.active {
  opacity: 1;
}

.news-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 100px;
}

@media only screen and (max-width: 1200px) {
  .news-wrap {
    justify-content: space-around;
  }
}

.news-wrap .news-card {
  margin-bottom: 40px;
}

.archive {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 40px;
}

@media only screen and (max-width: 1200px) {
  .archive {
    width: 320px;
  }
}

@media only screen and (max-width: 750px) {
  .archive {
    width: 100%;
    justify-content: flex-start;
  }
}

.archive_title {
  font-size: 21px;
  font-family: "Montserrat SemiBold", Helvetica, Arial, sans-serif;
  line-height: 70px;
  text-align: right;
}

@media only screen and (max-width: 750px) {
  .archive_title {
    text-align: left;
  }
}

.archive_list {
  display: flex;
  margin-top: 40px;
  flex-wrap: wrap;
}

@media only screen and (max-width: 750px) {
  .archive_list {
    margin-top: 10px;
  }
}

.archive_link {
  width: 50%;
  display: block;
  text-align: right;
  font-size: 15px;
  line-height: 48px;
  color: #282828;
  font-family: "Roboto Medium", Helvetica, Arial, sans-serif;
}

@media only screen and (min-width: 1201px) {
  .archive_link:hover {
    color: #5736AA;
  }
}

@media only screen and (max-width: 1200px) {
  .archive_link {
    width: 33%;
  }
}

@media only screen and (max-width: 750px) {
  .archive_link {
    text-align: left;
  }
}

.pagination {
  display: flex;
  align-items: center;
  color: #DDD9E6;
  font-size: 24px;
  word-spacing: -1.6px;
}

.pagination_link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border: 1px transparent solid;
  border-radius: 100%;
  color: #DDD9E6;
  font-size: 14px;
  font-family: "Montserrat SemiBold", Helvetica, Arial, sans-serif;
}

.pagination_link.active {
  border: 1px #DBD2F1 solid;
  color: #5736AA;
}

@media only screen and (min-width: 1201px) {
  .pagination_link:hover {
    color: #5736AA;
  }
}

.pagination li {
  list-style: none;
}

.pagination li.active a {
  border: 1px #DBD2F1 solid;
  color: #5736AA;
}

.pagination_prev {
  margin-right: 14px;
}

.pagination_next {
  margin-left: 14px;
}

.pagination_prev, .pagination_next {
  border: 1px #DBD2F1 solid;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border-radius: 100%;
  position: relative;
}

.pagination_prev .arrow-right, .pagination_next .arrow-right {
  opacity: 0.5;
  margin: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background-image: url("../img/svg-sprite/sprite.svg");
  background-position: -4px -2246px;
  width: 16px;
  height: 13px;
  background-repeat: no-repeat;
}

.pagination_prev .arrow-left, .pagination_next .arrow-left {
  opacity: 0.5;
  margin: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background-image: url("../img/svg-sprite/sprite.svg");
  background-position: -4px -2263px;
  width: 16px;
  height: 13px;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 1201px) {
  .pagination_prev:hover .arrow-right, .pagination_prev:hover .arrow-left, .pagination_next:hover .arrow-right, .pagination_next:hover .arrow-left {
    opacity: 1;
  }
}

@media only screen and (max-width: 1200px) {
  .pagination {
    justify-content: center;
  }
}

.pagination.mob {
  display: none;
}

@media only screen and (max-width: 750px) {
  .pagination {
    display: none;
  }
  .pagination.mob {
    display: flex;
    margin-bottom: 20px;
  }
}

.more-news {
  margin-top: 150px;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  .more-news {
    flex-direction: column-reverse;
  }
}

.more-news_flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  .more-news_flex {
    justify-content: space-around;
  }
}

.more-news_wrap {
  width: 66%;
  min-width: 66%;
}

@media only screen and (max-width: 1200px) {
  .more-news_wrap {
    width: 100%;
    min-width: 100%;
  }
}

.more-news_wrap .news-card_min {
  margin-bottom: 20px;
  margin-left: -30px;
}

@media only screen and (max-width: 750px) {
  .more-news_wrap .news-card_min {
    margin-left: 0;
  }
  .more-news_wrap .news-card_min .news-card_title {
    padding-left: 0;
    padding-right: 0;
  }
  .more-news_wrap .news-card_min .news-card_time {
    left: 0;
  }
  .more-news_wrap .news-card_min .news-card_view {
    right: 0;
  }
}

.more-news_control .title {
  text-align: right;
}

@media only screen and (max-width: 1200px) {
  .more-news_control .title {
    margin-bottom: 30px;
    text-align: center;
  }
}

.more-news_btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 70px;
}

.more-news_btn.mob {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .more-news_btn {
    display: none;
  }
  .more-news_btn.mob {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}

.more-news_prev, .more-news_next {
  border: 1px #DBD2F1 solid;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border-radius: 100%;
  position: relative;
  margin-left: 14px;
  font-size: 0;
}

.more-news_prev a, .more-news_next a {
  display: block;
  width: 100%;
  height: 100%;
}

.more-news_prev .arrow-right, .more-news_next .arrow-right {
  opacity: 0.5;
  margin: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background-image: url("../img/svg-sprite/sprite.svg");
  background-position: -4px -2246px;
  width: 16px;
  height: 13px;
  background-repeat: no-repeat;
  pointer-events: none;
}

.more-news_prev .arrow-left, .more-news_next .arrow-left {
  opacity: 0.5;
  margin: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background-image: url("../img/svg-sprite/sprite.svg");
  background-position: -4px -2263px;
  width: 16px;
  height: 13px;
  background-repeat: no-repeat;
  pointer-events: none;
}

@media only screen and (min-width: 1201px) {
  .more-news_prev:hover .arrow-right, .more-news_prev:hover .arrow-left, .more-news_next:hover .arrow-right, .more-news_next:hover .arrow-left {
    opacity: 1;
  }
}

@media only screen and (max-width: 750px) {
  .more-news_prev, .more-news_next {
    margin: 0 10px;
  }
}

.more-news .bx-viewport {
  overflow: visible !important;
}

.article_info {
  position: relative;
  font-size: 15px;
  color: #B5B5B5;
  margin-top: 30px;
  margin-bottom: 40px;
  height: 20px;
}

.article_info .news-card_time {
  top: 0;
  left: 0;
}

.article_info .news-card_view {
  top: 0;
  left: 300px;
}

@media only screen and (max-width: 750px) {
  .article_info .news-card_view {
    left: auto;
    right: 0;
  }
}

.article_control {
  display: flex;
  align-items: center;
  margin-top: 43px;
  margin-bottom: 115px;
}

@media only screen and (max-width: 750px) {
  .article_control {
    justify-content: center;
    margin-bottom: 70px;
  }
}

.article_btn {
  position: relative;
  cursor: pointer;
  margin-left: -10px;
}

.article_btn:before {
  content: '';
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 3px;
  height: 3px;
  background: #DBD2F1;
  border-radius: 100%;
}

.article_btn.active .article_index {
  transform: scale(1);
}

.article_btn.active .article_index svg {
  transition: opacity .1s, stroke-dashoffset 5s .5s;
  opacity: 1;
  stroke-dashoffset: -251px;
}

.article_index {
  width: 42px;
  min-width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5736AA;
  cursor: pointer;
  font-size: 14px;
  font-family: "Montserrat SemiBold", Helvetica, Arial, sans-serif;
  border: 1px #DBD2F1 solid;
  border-radius: 100%;
  transform: scale(0);
  background: #fff;
  transition: all .5s;
}

.article_index svg {
  position: absolute;
  top: -2px;
  left: -2px;
  width: 44px;
  height: 44px;
  stroke-dasharray: 126px;
  stroke-dashoffset: -126px;
  transform: rotate(-90deg);
  transition: opacity .3s, stroke-dashoffset 0s .3s;
  opacity: 0;
}

.article_next {
  width: 42px;
  min-width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 100%;
  background: #EAE7F1;
  transform: rotate(-90deg);
  margin-left: 10px;
}

.article_next:before {
  content: '';
  background-image: url("../img/svg-sprite/sprite.svg");
  background-position: -4px -2185px;
  width: 15px;
  height: 17px;
  background-repeat: no-repeat;
  opacity: 0.1;
  transition: all .5s;
}

@media only screen and (min-width: 1201px) {
  .article_next:hover:before {
    opacity: .5;
  }
}

.article_slider {
  display: flex;
}

@media only screen and (max-width: 750px) {
  .article_slider {
    display: block;
  }
}

.article_slider_wrap {
  width: 100%;
}

.article_slide .bx-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.article_slider-js {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 750px) {
  .article_slider-js {
    text-align: center;
  }
}

.article_controls {
  display: flex;
  justify-content: space-between;
}

.article_btn-link {
  display: flex;
  justify-content: flex-end;
}

.article_prev-link, .article_next-link {
  border: 1px #DBD2F1 solid;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border-radius: 100%;
  position: relative;
  margin-left: 14px;
  font-size: 0;
}

.article_prev-link .arrow-right, .article_next-link .arrow-right {
  opacity: 0.5;
  margin: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background-image: url("../img/svg-sprite/sprite.svg");
  background-position: -4px -2246px;
  width: 16px;
  height: 13px;
  background-repeat: no-repeat;
  pointer-events: none;
}

.article_prev-link .arrow-left, .article_next-link .arrow-left {
  opacity: 0.5;
  margin: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background-image: url("../img/svg-sprite/sprite.svg");
  background-position: -4px -2263px;
  width: 16px;
  height: 13px;
  background-repeat: no-repeat;
  pointer-events: none;
}

@media only screen and (min-width: 1201px) {
  .article_prev-link:hover .arrow-right, .article_prev-link:hover .arrow-left, .article_next-link:hover .arrow-right, .article_next-link:hover .arrow-left {
    opacity: 1;
  }
}

@media only screen and (max-width: 750px) {
  .article_prev-link, .article_next-link {
    margin: 0 10px;
  }
}

.article-more {
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 750px) {
  .article-more .btn_text {
    justify-content: center;
  }
}

@media only screen and (max-width: 1200px) {
  .article-more {
    display: block;
  }
}

.article-more_news .news-card {
  margin-right: 11px;
}

@media only screen and (max-width: 750px) {
  .article-more_news .news-card {
    margin: auto;
    margin-bottom: 40px;
  }
}

.article-more_news-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 70px;
  margin-top: 40px;
}

@media only screen and (max-width: 1200px) {
  .article-more_news-wrap {
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 750px) {
  .article-more_news-wrap {
    display: block;
  }
}

@media only screen and (max-width: 1200px) {
  .article-more_blog {
    margin: auto;
    margin-top: 70px;
  }
}

.article-more_blog .title_min {
  margin-bottom: 15px;
}

.article-more_blog .btn_text {
  margin-top: 30px;
}

.article-more_blog .news-card_min {
  margin-left: -30px;
}

@media only screen and (max-width: 1200px) {
  .article-more_blog .news-card_min {
    margin-left: 0;
    display: inline-block;
  }
}

@media only screen and (min-width: 1201px) {
  .article-more_blog .news-card_min:hover {
    z-index: 2;
  }
}

@media only screen and (max-width: 750px) {
  .article-more_blog .news-card_min {
    margin-left: 0;
    width: 100%;
  }
  .article-more_blog .news-card_min .news-card_title {
    padding-left: 0;
    padding-right: 0;
  }
  .article-more_blog .news-card_min .news-card_time {
    left: 0;
  }
  .article-more_blog .news-card_min .news-card_view {
    right: 0;
  }
}

.membership_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 80px;
}

@media only screen and (max-width: 750px) {
  .membership_top {
    margin-top: 30px;
    width: 100%;
    overflow: hidden;
  }
}

.membership_top .horizontal {
  margin-left: -50px;
}

@media only screen and (max-width: 1200px) {
  .membership_top .horizontal {
    margin-left: 0;
  }
}

@media only screen and (max-width: 750px) {
  .membership_top .horizontal {
    margin-left: auto;
    margin-right: auto;
  }
}

.membership_top .title_min {
  margin-bottom: 50px;
}

@media only screen and (max-width: 750px) {
  .membership_top .title_min {
    margin-bottom: 30px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 750px) {
  .membership_top {
    display: block;
  }
}

.membership_right {
  margin-left: 50px;
}

@media only screen and (max-width: 750px) {
  .membership_right {
    margin-left: 0;
  }
}

.stage {
  margin-top: 130px;
}

.stage .title {
  text-align: center;
  margin-bottom: 100px;
}

.stage_wrap {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 0 -60px;
}

@media only screen and (max-width: 1200px) {
  .stage_wrap {
    margin: 0;
  }
}

@media only screen and (max-width: 750px) {
  .stage_wrap {
    display: block;
  }
}

.stage_item {
  width: 230px;
}

@media only screen and (max-width: 750px) {
  .stage_item {
    width: 100%;
    height: 200px;
  }
  .stage_item:nth-child(odd) {
    padding-left: 50%;
  }
  .stage_item:nth-child(even) {
    padding-right: 50%;
  }
}

.stage_text {
  font-family: "Roboto Medium", Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
}

.stage_number {
  background-image: linear-gradient(-135deg, #5235AB 0%, #9044A0 100%);
  box-shadow: 0 8px 9px 0 rgba(120, 62, 156, 0.21), 0 8px 15px 0 rgba(120, 62, 156, 0.12);
  border-radius: 100%;
  width: 55px;
  height: 55px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 60px;
}

@media only screen and (max-width: 750px) {
  .stage_number {
    margin-bottom: 30px;
  }
}

.stage_number:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-image: linear-gradient(-196deg, #5235AB 0%, #9044A0 100%);
  transition: all .5s;
}

.stage_number span {
  position: relative;
  z-index: 2;
  color: #fff;
  font-family: "Montserrat SemiBold", Helvetica, Arial, sans-serif;
  font-size: 14px;
}

@media only screen and (min-width: 1201px) {
  .stage_number:hover:before {
    opacity: 0;
  }
}

.stage_doted-c {
  position: absolute;
  height: 100px;
  left: 40%;
  width: 20%;
  top: -20px;
  background: url("../img/general/doted.png") center bottom no-repeat;
}

@media only screen and (max-width: 750px) {
  .stage_doted-c {
    width: 220px;
    transform: rotate(124deg);
    top: 290px;
    left: 49px;
  }
}

.stage_doted-c svg {
  position: absolute;
  transform: translate(-100%, -15px) rotate(-70deg);
  bottom: 0;
  opacity: 0;
}

@media only screen and (min-width: 1200px) {
  .stage_doted-c svg {
    animation: air 12s infinite linear;
    animation-delay: 4s;
  }
}

.stage_doted-c.left {
  left: 150px;
  transform: scaleY(-1);
}

@media only screen and (max-width: 1200px) {
  .stage_doted-c.left {
    left: 15%;
  }
}

@media only screen and (max-width: 750px) {
  .stage_doted-c.left {
    width: 220px;
    transform: rotate(-124deg);
    top: 80px;
    left: 19px;
  }
}

@media only screen and (min-width: 1200px) {
  .stage_doted-c.left svg {
    animation: air 12s infinite linear;
    animation-delay: 0s;
  }
}

.stage_doted-c.right {
  left: auto;
  right: 150px;
  transform: scaleY(-1);
}

@media only screen and (max-width: 750px) {
  .stage_doted-c.right {
    width: 220px;
    transform: rotate(-124deg);
    top: 500px;
    left: 19px;
  }
}

@media only screen and (max-width: 1200px) {
  .stage_doted-c.right {
    right: 15%;
  }
}

@media only screen and (min-width: 1200px) {
  .stage_doted-c.right svg {
    animation: air 12s infinite linear;
    animation-delay: 8s;
  }
}

@keyframes air {
  0% {
    opacity: 0;
    transform: translate(-100%, -15px) rotate(-70deg);
  }
  66% {
    opacity: 0;
    transform: translate(-100%, -15px) rotate(-70deg);
  }
  74% {
    opacity: 1;
    transform: translate(325%, 5px) rotate(-80deg);
  }
  82% {
    opacity: 1;
    transform: translate(650%, 10px) rotate(-90deg);
  }
  91% {
    opacity: 1;
    transform: translate(975%, 5px) rotate(-100deg);
  }
  100% {
    opacity: 0;
    transform: translate(1400%, -10px) rotate(-110deg);
  }
}

.done_massage {
  display: none;
}

.done_massage_text {
  position: absolute;
  transform: translate(0, -50%);
  left: 0;
  top: 50%;
  line-height: 24px;
}

.has-done .member_wrap {
  height: 250px;
}

@media only screen and (max-width: 750px) {
  .has-done .member_wrap {
    height: 400px;
  }
}

.has-done .member_wrap:after {
  opacity: 1;
}

.has-done .member_form {
  display: none;
}

.has-done .informed_form {
  display: none;
}

.has-done .done_massage {
  display: block;
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 1200px) {
  .done_massage_m {
    margin-top: 50px;
  }
}

.bx-slide-main_wrap {
  margin-bottom: 24px;
}

.bx-slide-main_wrap .bx-controls-direction {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}

.bx-slide-main_wrap .bx-prev:before {
  content: '';
  opacity: 0.5;
  margin: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background-image: url("../img/svg-sprite/sprite.svg");
  background-position: -4px -2263px;
  width: 16px;
  height: 13px;
  background-repeat: no-repeat;
  pointer-events: none;
}

.bx-slide-main_wrap .bx-next:before {
  content: '';
  opacity: 0.5;
  margin: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background-image: url("../img/svg-sprite/sprite.svg");
  background-position: -4px -2246px;
  width: 16px;
  height: 13px;
  background-repeat: no-repeat;
  pointer-events: none;
}

.bx-slide-main_wrap .bx-prev, .bx-slide-main_wrap .bx-next {
  border: 1px #DBD2F1 solid;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border-radius: 100%;
  position: relative;
  margin-left: 14px;
  font-size: 0;
}

.bx-slide-main_wrap .bx-prev .arrow-right, .bx-slide-main_wrap .bx-next .arrow-right {
  opacity: 0.5;
  margin: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background-image: url("../img/svg-sprite/sprite.svg");
  background-position: -4px -2246px;
  width: 16px;
  height: 13px;
  background-repeat: no-repeat;
  pointer-events: none;
}

.bx-slide-main_wrap .bx-prev .arrow-left, .bx-slide-main_wrap .bx-next .arrow-left {
  opacity: 0.5;
  margin: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background-image: url("../img/svg-sprite/sprite.svg");
  background-position: -4px -2263px;
  width: 16px;
  height: 13px;
  background-repeat: no-repeat;
  pointer-events: none;
}

@media only screen and (min-width: 1201px) {
  .bx-slide-main_wrap .bx-prev:hover:before, .bx-slide-main_wrap .bx-next:hover:before {
    opacity: 1;
  }
}

@media only screen and (max-width: 750px) {
  .bx-slide-main_wrap .bx-prev, .bx-slide-main_wrap .bx-next {
    margin: 0 10px;
  }
}

.bx-slide-main_wrap img {
  width: auto !important;
  max-height: 380px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  left: 50%;
  transform: translateX(-50%);
}

.error-page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: -150px;
  background: url("../img/general/404.png") center no-repeat;
  background-size: contain;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .error-page .radar {
    width: 250px;
    height: 250px;
  }
}

@media only screen and (max-width: 1200px) {
  .error-page .radar {
    width: 200px;
    height: 200px;
    position: relative;
    left: 0;
    top: 0;
    margin: 0;
  }
}

@media only screen and (max-width: 750px) {
  .error-page .radar {
    width: 100px;
    height: 100px;
    position: relative;
    left: 0;
    top: 0;
    margin: 0;
  }
}

.error-page_title {
  font-size: 270px;
  display: flex;
  color: #5838AA;
  font-family: "Montserrat ExtraBold", Helvetica, Arial, sans-serif;
  align-items: center;
}

@media only screen and (max-width: 750px) {
  .error-page_title {
    font-size: 100px;
  }
}

.error-page_text {
  font-family: "Montserrat SemiBold", Helvetica, Arial, sans-serif;
  font-size: 21px;
  margin-top: 40px;
}

.informed {
  margin-top: 70px;
  padding-top: 70px;
  height: 360px;
  background: url("../img/general/informed.png") center no-repeat;
  background-size: contain;
}

@media only screen and (max-width: 1200px) {
  .informed .wrapper {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 750px) {
  .informed .wrapper {
    display: block;
  }
}

@media only screen and (max-width: 750px) {
  .informed {
    height: 460px;
    background-size: cover;
    background-position: center;
  }
}

.informed .form-group {
  display: block;
  position: relative;
}

.informed .form-group.has-error .help-block {
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-width: 1200px) {
  .informed .form-group {
    margin: 30px 0;
  }
}

@media only screen and (max-width: 750px) {
  .informed .form-group {
    width: 100%;
  }
}

.informed .control-label {
  display: block;
  font-family: "Montserrat SemiBold", Helvetica, Arial, sans-serif;
  color: #6C6C6C;
  font-size: 15px;
  margin-bottom: 9px;
}

.informed .help-block {
  position: absolute;
  left: 0;
  top: 100%;
  font-size: 12px;
  color: #E94E71;
  margin-top: 4px;
  opacity: 0;
  visibility: hidden;
  transition: all .5s;
}

.informed .form-control {
  display: block;
  width: 390px;
  height: 43px;
  background: rgba(237, 236, 241, 0.4);
  font-size: 15px;
  margin-right: 30px;
  color: #282828;
  padding-left: 20px;
  border-radius: 6px;
}

@media only screen and (max-width: 1200px) {
  .informed .form-control {
    margin-right: 0;
  }
}

@media only screen and (max-width: 750px) {
  .informed .form-control {
    width: 100%;
  }
}

.informed_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  .informed_flex {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 750px) {
  .informed_flex {
    width: 100%;
  }
}

.informed_form {
  display: flex;
  position: relative;
  z-index: 1;
  align-items: flex-end;
}

@media only screen and (max-width: 1200px) {
  .informed_form {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 750px) {
  .informed_form {
    width: 100%;
  }
}
