/* Your custom fonts here */

/* Example

@font-face {
    font-family: 'CustomFont';
    src: url('../fonts/Custom-Font.eot');
    src: url('../fonts/Custom-Font.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Custom-Font.woff') format('woff'),
         url('../fonts/Custom-Font.svg#custom_font') format('svg');
    font-weight: 400;       // For normal width. It could has another value   
    font-style: normal;     // Also could has another value
    }


// Var for using custom font
$CustomFont: 'CustomFont', Helvetica, Arial, sans-serif;
*/


@font-face {
  font-family: 'Roboto Regular';
  src: url('../fonts/Roboto-Regular.eot');
  src: url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto-Regular.woff') format('woff'),
  url('../fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Medium';
  src: url('../fonts/Roboto-Medium.eot');
  src: url('../fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto-Medium.woff') format('woff'),
  url('../fonts/Roboto-Medium.ttf') format('truetype');

}

@font-face {
  font-family: 'Roboto Bold';
  src: url('../fonts/Roboto-Bold.eot');
  src: url('../fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto-Bold.woff') format('woff'),
  url('../fonts/Roboto-Bold.ttf') format('truetype');

}

@font-face {
  font-family: 'Montserrat SemiBold';
  src: url('../fonts/Montserrat-SemiBold.eot');
  src: url('../fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserrat-SemiBold.woff') format('woff'),
  url('../fonts/Montserrat-SemiBold.ttf') format('truetype');

}

@font-face {
  font-family: 'Montserrat ExtraBold';
  src: url('../fonts/Montserrat-ExtraBold.eot');
  src: url('../fonts/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserrat-ExtraBold.woff') format('woff'),
  url('../fonts/Montserrat-ExtraBold.ttf') format('truetype');

}


$FontRobotoRegular: 'Roboto Regular', Helvetica, Arial, sans-serif;
$FontRobotoMedium: 'Roboto Medium', Helvetica, Arial, sans-serif;
$FontRobotoBold: 'Roboto Bold', Helvetica, Arial, sans-serif;
$FontMontserratSemiBold: 'Montserrat SemiBold', Helvetica, Arial, sans-serif;
$FontMontserratExtraBold: 'Montserrat ExtraBold', Helvetica, Arial, sans-serif;
