// GUI stylies of the project
// For example, styles of the links, buttons and so on.

.h1, h1 {
  font-size: 50px;
  font-family: $FontMontserratSemiBold;
  line-height: 70px;
  color: #282828;
  position: relative;
  //
  word-wrap: break-word;
  //
  &_abs {
    position: absolute;
    bottom: 100%;
    font-size: 18px;
    line-height: 45px;
    @include respond-to(t) {
      @include position(centerX)
    }
  }
  @include respond-to(t) {
    font-size: 30px;
    line-height: 45px;
  }
}

.title, h2 {
  font-size: 36px;
  font-family: $FontMontserratSemiBold;
  line-height: 48px;
  color: #282828;
  &.center {
    text-align: center;
  }
  @include respond-to(t) {
    font-size: 30px;
    line-height: 37px;
  }
}

.title_min, h3 {
  font-size: 21px;
  font-family: $FontMontserratSemiBold;
  line-height: 36px;
  color: #282828;
  &.center {
    text-align: center;
  }
}

h4 {
  font-size: 15px;
  font-family: $FontMontserratSemiBold;
  line-height: 24px;
  color: #282828;
}

h5 {
  font-size: 13px;
  font-family: $FontMontserratSemiBold;
  line-height: 18px;
  color: #282828;
}

p {
  font-size: 15px;
  line-height: 24px;
  color: #6C6C6C;
}

.btn {
  box-shadow: 0 8px 9px 0 rgba(120, 62, 156, 0.21), 0 8px 15px 0 rgba(120, 62, 156, 0.12);
  border-radius: 100px;
  height: 45px;
  width: 207px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $FontRobotoBold;
  font-size: 15px;
  color: #fff;
  transition: all .5s;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: none;
  text-align: center;
  &:after, &:before {
    content: '';
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(-135deg, #5235AB 0%, #9044A0 100%);
    z-index: -1;
    transition: all .5s;
  }
  &:before {
    background-image: linear-gradient(-196deg, #5235AB 0%, #9044A0 100%);
  }
  @include hover {
    &:after {
      opacity: 0;
    }
    box-shadow: 0 8px 9px 0 rgba(120, 62, 156, 0.21), 0 8px 15px 0 rgba(120, 62, 156, 0.12);
  }
  &_min {
    cursor: pointer;
    width: auto;
    padding: 0 45px;
  }
  &_text {
    font-size: 16px;
    font-family: $FontMontserratSemiBold;
    color: #282828;
    cursor: pointer;
    display: flex;
    align-items: center;
    .arrow-right {
      content: '';
      @include bg-svg($arrow_right);
      margin-left: 15px;
      display: inline-block;
    }

    .arrow-left {
      content: '';
      @include bg-svg($arrow_left2);
      margin-right: 15px;
      display: inline-block;
    }
    .btn_plus {
      margin-right: 15px;
    }
  }
  &_link {
    font-size: 14px;
    font-family: $FontRobotoMedium;
    color: #282828;
    cursor: pointer;
    line-height: 16px;
  }
  &_plus {
    background-image: linear-gradient(-135deg, #5235AB 0%, #9044A0 100%);
    box-shadow: 0 8px 9px 0 rgba(120, 62, 156, 0.21), 0 8px 15px 0 rgba(120, 62, 156, 0.12);
    border-radius: 100%;
    width: 45px;
    height: 45px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      background-image: linear-gradient(-196deg, #5235AB 0%, #9044A0 100%);
      transition: all .5s;
    }
    &:after {
      content: '+';
      @include position(centerY);
      color: #fff;
      font-size: 18px;
      line-height: 18px;
      font-family: $FontMontserratSemiBold;
      text-align: center;
      width: 100%;
      letter-spacing: 0;

    }
    @include hover {
      &:before {
        opacity: 0;
      }
    }
    &.active {
      &:after {
        content: '-';
        box-sizing: content-box;
        margin-top: -1px;
      }
    }
  }
  &_download {
    background-image: linear-gradient(-135deg, #5235AB 0%, #9044A0 100%);
    box-shadow: 0 8px 9px 0 rgba(120, 62, 156, 0.21), 0 8px 15px 0 rgba(120, 62, 156, 0.12);
    border-radius: 100%;
    width: 67px;
    height: 67px;
    position: relative;
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      background-image: linear-gradient(-196deg, #5235AB 0%, #9044A0 100%);
      transition: all .5s;
    }
    &:after {
      content: '';
      @include position(center);
      @include bg-svg($download);
    }
    @include hover {
      &:before {
        opacity: 0;
      }
    }
    &_loader {
      display: block;
      position: absolute;
      top: -1px;
      left: -1px;
      z-index: 2;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      svg {
        width: 100%;
        height: 100%;
        color: transparent;
        stroke-dasharray: 215px;
        stroke-dashoffset: -215px;
        transform: rotate(-90deg);
        transition: opacity .3s, stroke-dashoffset 0s .3s;
        opacity: 0;
      }
      &.active {
        svg {
          transition: opacity .1s, stroke-dashoffset 1s;
          opacity: 1;
          stroke-dashoffset: -429px;
        }
      }
    }
  }

  &_right {
    background-image: linear-gradient(-135deg, #5235AB 0%, #9044A0 100%);
    box-shadow: 0 8px 9px 0 rgba(120, 62, 156, 0.21), 0 8px 15px 0 rgba(120, 62, 156, 0.12);
    border-radius: 100%;
    width: 67px;
    height: 67px;
    position: relative;
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      background-image: linear-gradient(-196deg, #5235AB 0%, #9044A0 100%);
      transition: all .5s;
    }_wrap
    &:after {
      content: '';
      @include position(center);
      @include bg-svg($btn-right);
    }
    @include hover {
      &:before {
        opacity: 0;
      }
    }
  }
}

button.btn {
  display: block;
}

.center-mob {
  @include respond-to(t) {
    text-align: center;
  }
}

.news-card {
  width: 320px;
  height: 360px;
  display: block;
  position: relative;
  background: #FFFFFF;
  box-shadow: 0 3px 20px 0 rgba(113, 69, 203, 0.08);
  border-radius: 4px;
  transition: all .5s;
  font-size: 15px;
  color: #B5B5B5;

  @include hover {
    box-shadow: 0 14px 32px 0 rgba(113, 69, 203, 0.11);
  }
  @include respond-to(m) {
    width: 290px;
  }
  &_image {
    width: 100%;
    height: 196px;
    background-size: cover;
    display: block;
    background-position: center;
  }
  &_title {
    padding: 20px 30px;
    font-family: $FontRobotoMedium;
    font-size: 18px;
    color: #282828;
    line-height: 27px;
    height: 100px;
    overflow: hidden;
  }
  &_time {
    position: absolute;
    left: 30px;
    bottom: 25px;
  }
  &_view {
    position: absolute;
    right: 30px;
    bottom: 25px;
    &:before {
      content: '';
      display: inline-block;
      @include bg-svg($view);
      margin-right: 5px;
      vertical-align: middle;
    }
  }
  &_big {
    width: 650px;
    @include respond-to(t) {
      width: 320px;
    }
  }
  &_min {
    box-shadow: none;
    padding: 0;
    height: 140px;
  }

}

article {
  width: 580px;
  margin: auto;
  margin-top: 45px;
  @include respond-to(m) {
    width: 100%;
  }
  h3, h4, h5 {
    margin-bottom: 24px;
    margin-top:32px;
  }
  p {
    margin-bottom: 24px;
  }
  strong {
    color: #282828;
    font-family: $FontRobotoMedium;
  }
  ul {
    margin-top: 24px;
    & > li {
      font-size: 15px;
      line-height: 24px;
      color: #6C6C6C;
      margin-bottom: 24px;
      position: relative;
      padding-left: 25px;
      &:before {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        background: #D1C6EB;
        border-radius: 100%;
        top: 10px;
        left: 0;
        position: absolute;
      }
    }
  }
  ol {
    margin-top: 24px;
   & >  li {
      font-size: 15px;
      line-height: 24px;
      color: #6C6C6C;
      margin-bottom: 24px;
      position: relative;
      padding-left: 35px;
      counter-increment: section;
      &:before {
        content: "0"counter(section);
      }
      &:nth-child(n+10) {
        &:before {
          content: counter(section);
        }
      }

      &:before {

        display: inline-block;
        top: 0px;
        left: 0;
        position: absolute;
        color: #282828;
        font-size: 14px;
        font-family:$FontMontserratSemiBold;
      }
    }
  }
  img {
    width: 100%;
    margin-bottom: 24px;
  }
  blockquote {
    margin-bottom: 24px;
    .autor {
      margin-top: 12px;
      text-align: right;
    }
  }
}