  $all: -4px -4px 1114px 656px 1122px 2346px './test.svg' 'all';
  $ua: -4px -664px 973px 631px 1122px 2346px './test.svg' 'ua';
  $europe: -4px -1299px 229px 304px 1122px 2346px './test.svg' 'europe';
  $asia: -4px -1607px 234px 255px 1122px 2346px './test.svg' 'asia';
  $america: -4px -1866px 234px 255px 1122px 2346px './test.svg' 'america';
  $download: -4px -2125px 12px 22px 1122px 2346px './test.svg' 'download';
  $view: -4px -2151px 19px 12px 1122px 2346px './test.svg' 'view';
  $arrow_right: -4px -2167px 18px 14px 1122px 2346px './test.svg' 'arrow_right';
  $air-bottom: -4px -2185px 15px 17px 1122px 2346px './test.svg' 'air-bottom';
  $air-right-grey: -4px -2206px 17px 16px 1122px 2346px './test.svg' 'air-right-grey';
  $air-right: -4px -2226px 17px 16px 1122px 2346px './test.svg' 'air-right';
  $arrow_right2: -4px -2246px 16px 13px 1122px 2346px './test.svg' 'arrow_right2';
  $arrow_left2: -4px -2263px 16px 13px 1122px 2346px './test.svg' 'arrow_left2';
  $fb: -4px -2280px 7px 16px 1122px 2346px './test.svg' 'fb';
  $fb_black: -4px -2300px 7px 16px 1122px 2346px './test.svg' 'fb_black';
  $btn-right: -4px -2320px 14px 12px 1122px 2346px './test.svg' 'btn-right';
  $bottom: -4px -2336px 8px 6px 1122px 2346px './test.svg' 'bottom';



@mixin svg-sprite-position($svg-sprite) {
  $svg-sprite-offset-x: nth($svg-sprite, 1);
  $svg-sprite-offset-y: nth($svg-sprite, 2);
  background-position: $svg-sprite-offset-x  $svg-sprite-offset-y;
}

@mixin bg-svg($svg-sprite, $repeat: no-repeat) {
    background-image: url('%=static=%svg-sprite/sprite.svg');
    @include svg-sprite-position($svg-sprite);
    width: nth($svg-sprite, 3);
    height: nth($svg-sprite, 4);
    background-repeat: $repeat;
}
