
.informed {
  .wrapper {
    @include respond-to(t) {
      display: flex;
      justify-content: center;
    }
    @include respond-to(m) {
      display: block;
    }
  }
  margin-top: 70px;
  padding-top: 70px;
  height: 360px;
  background: url("../img/general/informed.png")  center no-repeat;
  background-size: contain;
  @include respond-to(m) {
    height: 460px;
    background-size: cover;
    background-position: center;
  }
  .form-group {
    display: block;
    position: relative;
    &.has-error {
      .help-block {
        opacity: 1;
        visibility: visible;
      }
    }

    @include respond-to(t) {
      margin: 30px 0;
    }
    @include respond-to(m) {
      width: 100%;
    }

  }
  .control-label {
    display: block;
    font-family: $FontMontserratSemiBold;
    color: #6C6C6C;
    font-size: 15px;
    margin-bottom: 9px;
  }
  .help-block {
    position: absolute;
    left: 0;
    top: 100%;
    font-size: 12px;
    color: #E94E71;
    margin-top: 4px;
    opacity: 0;
    visibility: hidden;
    transition: all .5s;

  }
  .form-control {
    display: block;
    width: 390px;
    height: 43px;
    background: rgba(237, 236, 241, 0.40);
    font-size: 15px;
    margin-right: 30px;
    color: #282828;
    padding-left: 20px;
    border-radius: 6px;
    @include respond-to(t) {
      margin-right: 0;
    }
    @include respond-to(m) {
      width: 100%;
    }
  }
  &_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include respond-to(t) {
      flex-direction: column;
      align-items: flex-start;
      //justify-content: center;
    }
    @include respond-to(m) {
      width: 100%;
    }
  }
  &_form {
    display: flex;
    position: relative;
    z-index: 1;
    align-items: flex-end;
    @include respond-to(t) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    @include respond-to(m) {
      width: 100%;
    }
  }
}