//$mobile: 750px;
$mobile: 750px;
$tablet: 1200px;
$desctop: 1600px;
@mixin respond-to($media) {
  @if $media == m {
    @media only screen and (max-width: $mobile) {
      @content;
    }
  } @else if $media == mm {
    @media only screen and (max-width: 359px) {
      @content;
    }
  } @else if $media == ls-m {
    @media only screen and (max-width: $mobile) and (orientation: landscape) {
      @content;
    }
  } @else if $media == t {
    @media only screen and (max-width: $tablet) {
      @content;
    }
  } @else if $media == ls-t {
    @media only screen and (max-width: $tablet) and (orientation: landscape) {
      @content;
    }
  } @else if $media == only-t {
    @media only screen and (max-width: $tablet) and (min-width: $mobile) {
      @content;
    }

  } @else if $media == d {
    @media only screen and (min-width: $tablet) {
      @content;
    }

  } @else if $media == dm {
    @media only screen and (min-width: $tablet) and (max-width: $desctop) {
      @content;
    }

  } @else if $media == 1440 {
    @media only screen and (max-width: 1380px) and (orientation: landscape) {
      @content;
    }

  } @else if $media == hover {
    @media only screen and (min-width: $tablet + 1) {

      @content;

    }

  }
}

@mixin hover {
  @media only screen and (min-width: $tablet + 1) {
    &:hover {
      @content;
    }
  }
}

//@mixin pixel {
//  background: url("/bg.png") center top no-repeat;
//  & > * {
//    opacity: 0.5;
//  }
//  @include respond-to(t) {
//    background-image: url("/bg-t.png");
//  }
//  @include respond-to(m) {
//    background-image: url("/bg-m.png");
//  }
//}

//@include respond-to(hd) {}
//@include respond-to(d) {}
//@include respond-to(t) {}
//@include respond-to(m) {}

@mixin position($position) {
  @if $position == center {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
  }
  @if $position == centerY {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
  }
  @if $position == centerX {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
  }
  @if $position == none {
    position: relative;
    transform: translate(0, 0);
    top: auto;
    left: auto;
  }
}

@mixin style($style) {
  @if $style == middle {
    &:before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      width: 1px;
    }
    &:before {
      margin-left: -1px;
    }
  }
  @if $style == clearfix {
    &:before {
      content: "";
      display: table;
      clear: both;
    }
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }
  @if $style == padding {
    padding-left: 40px;
    padding-right: 40px;
    @include respond-to(t) {
      padding-left: 33px;
      padding-right: 33px;
    }
    @include respond-to(m) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
